import {Typography, Grid} from '@mui/material';
import "./header.css"


export default function Header({url_background}) {
    
    return (

        <Grid container className="header_wrap_div" id="fff" style={{background: `url(${url_background})`}}>
            <Grid container p={0} item justifyContent="center" className="banner-content">
                <Grid item xs={12}>
                    <Typography variant="h1" id="new_future">
                        NEW ERA OF INVESTMENT
                    </Typography>
                </Grid>

                <Grid item xs={12}  md={6}>
                    <Typography variant="body1" className="short_text_header">
                    Discover endless real estate opportunities with Go4Invest. 
                    Whether you're a seasoned investor or a first-time estate buyer, we empower you with insights, 
                    a diverse property portfolio, and a supportive community.
                    </Typography>
                </Grid>

                <Grid item xs={12}>

                    <a href='#we_are_hook' className="btn btn_color header_button_block" data-animation="fadeInUp" data-delay=".6s">
                        <div className="btn_m">
                            <div className="btn_c">
                                <div className="btn_t1">EXPLORE</div>
                                <div className="btn_t2">EXPLORE</div>
                            </div>
                        </div>
                    </a>
                </Grid>
            </Grid>

            <Grid id="we_are_hook" container pb={0} item justifyContent="center" className="svg_container">
                <Grid item xs={12}>
                    <div className="banner-scroll-down">
                        <a href="#we_are_hook" className="section-link">
                            <span></span>
                            <span></span>
                            <span></span>
                        </a>
                    </div>
                </Grid>

            </Grid>
        </Grid>
    )
}