import * as React from 'react';
import { Box, Stepper, Step, Grid, StepLabel } from '@mui/material'
import { useForm, FormProvider } from "react-hook-form";
import General from './tabs/General/General';
import Details from './tabs/Details/Details';
import Location from './tabs/Location/Location'
import Overview from './tabs/Overview/Overview';
import Publishing from './tabs/Publishing/Publishing';
import { api } from '../../../../api/api';
import { useSelector, useDispatch } from 'react-redux';
import { selectProject, updateGeneralProjectInfo, updateDetailsProjectInfo, updateLocationProjectInfo, addProjectMedia,
    addProjectDrawings, addProjectCertificates, fetchSingleProject, setProjectId
} from '../../../../features/projects/projectsSlice';
import { useParams } from 'react-router-dom';

import './add_project.css'
import './tabs/tabs.css'

const steps = ['General', 'Features', 'Location', 'Overview', 'Publishing'];


export default function AddSingleProject({purpose=''}) {
    const dispatch = useDispatch()
    const {id} = useParams()
    const methods = useForm({mode:'onChange'})
    const project = useSelector((state) => selectProject(state, purpose))

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [success, setSuccess] = React.useState(false)

    const handleStep = (step) => () => {
        step === 4 && methods.trigger()
        setActiveStep(step);
        const newCompleted = {};
        for (let i = 0; i < step; i++) {
            newCompleted[i] = true
        }
        setCompleted(newCompleted);
    };

    React.useEffect(() => {
        if (purpose === 'edit' && project) {
            methods.setValue('location.address', project?.location.address)
        }
    }, [project])

    React.useEffect(() => {
        if (purpose === 'edit' && !project) {
            dispatch(fetchSingleProject(id))
            dispatch(setProjectId({project_id: id}))
        } else if (purpose !== 'edit'){
            dispatch(setProjectId({project_id: 0}))
        }
    }, [])

    const handleSave = (data) => {
        if (data?.details?.construction_materials === "") {
            data['details']['construction_materials'] = []
        }

        if (purpose === 'add') {
            api('/api/projects/', 'POST', data.general).then((res) => {
                api(`/api/${res.data.estate_category}/`, 'POST', {...data.details, project: res.data.id, ...data.features}).then(() => {
                    api('/api/location/', 'POST', {...data.location, project:res.data.id}).then(() => {
                        // Media 
                        api('/api/project_media/', 'POST', {...data.media, project:res.data.id}, false, { headers: {'Content-Type': 'multipart/form-data'}}).then(() => {
                            api('/api/project_drawings/', 'POST', {...data.drawings, project:res.data.id}, false, { headers: {'Content-Type': 'multipart/form-data'}}).then(() => {
                                api('/api/project_certificates/', 'POST', {...data.certificates, project:res.data.id}, false, { headers: {'Content-Type': 'multipart/form-data'}}).then(()=> {
                                    setTimeout(() => window.location.href = '/admin_panel/projects', 2000)
                                    setSuccess(true)
                                }).catch(() => { api(`/api/projects/${res.data.id}/`, 'DELETE') })
                            }).catch(() => { api(`/api/projects/${res.data.id}/`, 'DELETE') })     
                        }).catch(() => { api(`/api/projects/${res.data.id}/`, 'DELETE') })
                    }).catch(() => { api(`/api/projects/${res.data.id}/`, 'DELETE') })
                }).catch(() => { api(`/api/projects/${res.data.id}/`, 'DELETE') })                     
            })
        }
        else if (purpose === 'edit'){
            api(`/api/projects/${project.id}/`, 'PATCH', data.general).then((res) => {
                dispatch(updateGeneralProjectInfo({'id': project.id, data: res.data}))
                api(`/api/${data.general.estate_category}/${project.details.id}/`, 'PATCH', {...data.details, ...data.features}).then((res) => {
                    dispatch(updateDetailsProjectInfo({'id': project.id, data: res.data}))
                    api(`/api/location/${project.location.id}/`, 'PATCH', data.location).then((res) => {
                        dispatch(updateLocationProjectInfo({'id': project.id, data: res.data}))
                        
                        if (methods.formState.dirtyFields?.media) {
                            api(`/api/project_media/`, 'POST', {...data.media, project: project.id}, false, { headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
                                dispatch(addProjectMedia({'id': project.id, data: res.data}))
                            })
                        }
                        if (methods.formState.dirtyFields?.drawings) {
                            api('/api/project_drawings/', 'POST', {...data.drawings, project: project.id}, false, { headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
                                dispatch(addProjectDrawings({'id': project.id, data: res.data}))
                            })
                        }
                        if (methods.formState.dirtyFields?.certificates) {
                            api('/api/project_certificates/', 'POST', {...data.certificates, project: project.id}, false, { headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
                                dispatch(addProjectCertificates({'id': project.id, data: res.data}))
                            })
                        }
                        methods.resetField('media', {defaultValue: project?.media})
                        methods.resetField('drawings', {defaultValue: project?.drawings})
                        methods.resetField('certificates', {defaultValue: project?.certificates})
                        setSuccess(true)
                        setTimeout(() => setSuccess(false), 2000)
                    })
                })
            })
        } 
    }

    return (
        <Grid container m={0} p={2} alignItems="flex-start" className='wrap_container'>

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSave)}>
                    <Box sx={{ width: '100%' }}>
                        <Stepper nonLinear activeStep={activeStep} alternativeLabel className='stepper_box'>
                            {steps.map((label, index) => (
                                <Step key={label} className={index in completed ? 'step_completed' : 'step_not_completed'} >
                                    <StepLabel color="inherit" onClick={handleStep(index)}>
                                        {label}
                                        <span className={activeStep === index || index in completed ? "circle_complete" : 'circle'}></span>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {((purpose !== 'edit') || (purpose === 'edit' && project)) &&
                        <React.Fragment>
                            <General activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} project={project} purpose={purpose}/>
                            <Details activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} project={project}/>
                            <Location activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} project={project} purpose={purpose}/>
                            <Overview activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} project={project} purpose={purpose}/>
                            <Publishing activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} success={success} purpose={purpose}/>
                        </React.Fragment>
                    }
            
                    </Box>
                </form>
            </FormProvider>
        </Grid>
    )
}