import {Grid, Typography, Button, Link } from '@mui/material'
import * as React from 'react'
import './not_found.css'





export default function NotFound() {

    return (

        <Grid container p={2} className='error_page_container' justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
            <Grid item xs={12} sm={10} md={8} lg={7} alignSelf='flex-end' mb={5}>
                <Typography className='message_not_found'>
                    The page you are looking for might have been removed or temporarily unavailable
                </Typography>
            </Grid>

            <Grid item xs={12} sm={10} md={8} lg={7} alignSelf='baseline'>
                <Link m={1} href='/'>
                    <Button className='btn_not_found'>Back to home</Button>
                </Link>

                <Link m={1} href='/admin_panel/projects'>
                    <Button className='btn_not_found'>Go to admin page</Button>
                </Link>

            </Grid>

        </Grid>

    )
}