import { Grid, Typography } from "@mui/material";

const DATA = {
    // Real Estate
    'building_age': 'Building Age', 'infrastructure': 'Infrastructure', 'construction_materials': 'Construction Materials',
    'number_of_rooms': 'Number of Rooms', 'number_of_bathrooms': 'Number of Bathrooms', 'number_of_balcony': 'Number of Balcony',
    'number_of_terrace': 'Number of Terrace', 'number_of_floors': 'Number of Floors', 'floor_number': 'Floor Number',
    'garden_or_private_area': 'Garden or Private Area', 'heating_method': 'Heating Method',
    'ventilation_method': 'Ventilation Method', 'furnished': 'Furnished', 'security': 'Security', 'parking': 'Parking',
    'elevator': 'Elevator',
    // Land & Terrain
    'land_condition': 'Land Condition', 'environmental_condition': 'Environmental Condition', 'zone_number': 'Zone Number',
    'parcel_number': 'Parcel Number', 'sheet_number': 'Sheet Number', 'floor_area_ration': 'Floor Area Ration',
    'gauge': 'Gauge', 'flat_for_land_method': 'Flat for Land Method', 'swap_statement': 'Swap Statement', 'from_owner': 'From Owner',
    'flat_size_flat_number': 'Flat Size Flat Number',
    // Tourism & Facility
    'number_of_beds': 'Number of Beds', 'count_of_stars': 'Count of Stars', 'ground_survey': 'Ground Survey',
    'number_of_pools': 'Number of Pools', 'number_of_buildings': 'Number of Buildings', 'airstrip': 'Airstrip',
    // Business
    'number_of_workspace': 'Number of Workspace', 'number_of_store': 'Number of Store',
    'number_of_employee_capacity': 'Number of Employee Capacity', 'business_type': 'Business Type'
}

export default function TabPanelDetails({data}) {

    return (
        <Grid container xs={12} p={0}>
            {data ? Object.keys(data)?.map((row) => (

                (data[row] !== '' && data[row] !== undefined && (typeof data[row] !== 'object') && row !== 'project' && row !== 'id') && (
                <Grid container xs={12} p={0} mt={1} alignItems='flex-start'>
                    <Grid item xs={6}>
                        <Typography color='grey'>{DATA[row]}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color='white'>
                            {data[row] === false 
                                ? 'no'
                                : data[row] === true 
                                    ? 'yes'
                                    : data[row]
                            }
                        </Typography>
                    </Grid>
                </Grid>
                )
    
            ))
            :  <Grid container xs={12} justifyContent='center' mt={5}>
                <Typography variant="h5" color='grey'>No Content</Typography>
            </Grid> 
            }
        </Grid>
    )
}