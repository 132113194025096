export function media_type_validator(files) {
    for (let i in files) {
        if (files[i].type !== 'image/png' && files[i].type !== 'image/jpeg' && files[i].type.slice(0, 5) !== 'video') {
            return false
        }
    }
    return true
}

export function draw_type_validator(files) {
    for (let i in files) {
        if (files[i].type !== 'image/png' && files[i].type !== 'image/jpeg') {
            return false
        }
    }
    return true
}

export function cert_type_validator(files) {
    for (let i in files) {
        if (files[i].type !== 'application/pdf') {
            return false
        }
    }
    return true
}

export function file_size_validator(files) {
    for (let i in files) {
        if ((files[i].type === 'image/png' || files[i].type === 'image/jpeg' || files[i].type === 'application/pdf') && files[i].size > 1000000) {
            return false
        }
    }
    return true
}

export function video_size_validator(files) {
    for (let i in files) {
        if (files[i].type.slice(0, 5) === 'video'  && files[i].size > 3000000000) {
            return false
        }
    }
    return true
}

export function at_least_one_image(files, project_media=null) {
    let count = 0
    for (let key in files) {
        if (files[key].type.slice(0, 5) === 'image'){
            count++
        }
    }

    if (project_media){
        for (let el in project_media){
            project_media[el].type.slice(0, 5) === 'image' && count++
        }
    }

    if (count > 0) {
        return true
    }
    return false
}


export function count_media_validator(files, project_media=null) {
    let video_count = 0; let image_count = 0;

    for (let i in files) {
        (files[i].type === 'image/png' || files[i].type === 'image/jpeg')
            ? image_count++
            : video_count++
    }
    
    if (project_media) {
        for (let el in project_media){
            (project_media[el].type === 'image/png' || project_media[el].type === 'image/jpeg')
                ? image_count++
                : video_count++
        }
    }
    
    if (video_count > 1) {
        return false
    }

    if (image_count > 9) {
        return false
    }

    return true
}

export function logo_size_validator(files) {
    for (let i in files) {
        if (files[i].size > 500000) {
            return false
        }
    }
    return true
}

export function is_english_text(text) {
    const englishRegex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?/`/~\n\r ]*$/;
    return englishRegex.test(text);
}






