import { Grid, Button, Skeleton, Box } from '@mui/material';
import Slider from "react-slick";
import React, { useRef, useState, useEffect } from 'react';
import SortImagesDialog from '../../../../components/dialogs/SortImagesDialog/SortImagesDialog';
import ReactPlayer from 'react-player'

export default function OverviewSlider({ imagePreviews, purpose = '', project }) {
    const [sortImages, setSortImages] = useState(false)
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    const count_prev = imagePreviews?.length ? imagePreviews?.length : 0
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);

    function byField(fieldName){
        return (a, b) => a[fieldName] > b[fieldName] ? 1 : -1;
      }

    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);


    return (
        <Grid container item xs={12} sx={{ p: { xs: 0, md: 0 } }} justifyContent='center'>
            {imagePreviews
                && <Grid container p={1} item xs={12}>
                    <Slider arrows={false} asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
                        {imagePreviews.sort(byField('position')).map((preview, index) => (
                            <div key={index}>
                                {preview.type === 'image' && <img className='preview_image' height={purpose === 'view' ? 450 : 350} key={index} src={preview.file} alt='Preview' />}
                                {preview.type === 'video' && <ReactPlayer className='preview_video' controls={true} url={preview.file} />}
                            </div>
                        ))}

                    </Slider>
                    
                    <Slider
                        className={imagePreviews.length > 1 ? 'second_gallery_slider': 'second_gallery_slider hidden_second_gallery'}
                        asNavFor={nav1}
                        ref={slider => (sliderRef2 = slider)}
                        slidesToShow={
                            count_prev > 4 
                                ? 4
                                : count_prev > 0 && count_prev < 5
                                    ? count_prev
                                    :0
                        }
                        swipeToSlide={true}
                        focusOnSelect={true}
                    >
                        {imagePreviews.sort(byField('position')).map((preview, index) => (
                            <Box align='center' width={'fit-content'}>
                                {preview.type === 'image' && <img width={60} height={40} key={index} src={preview.file} alt='Preview' />}
                                {preview.type === 'video' && <ReactPlayer width={60} height={40} url={preview.file} />}
                            </Box>
                        ))}

                    </Slider>
                

                </Grid>
            }

            {imagePreviews?.length === 0 &&
                <Grid item xs={12} className="slider-container" >
                    <Skeleton variant="rounded" height={300} sx={{ bgcolor: '#7e7e7e' }} />
                </Grid>
            }

            {(purpose === 'edit' || purpose === 'add') &&
                <Grid item xs={12} mt={1} align='center'>
                    <Button className="view_btn_overview" onClick={() => setSortImages(true)}>Sort/Edit Images</Button>
                </Grid>



            }
            {(purpose === 'edit' || purpose === 'add') &&
                <SortImagesDialog open={sortImages} setOpen={setSortImages} project={project}/>
            }
        </Grid>
    )
}