import * as React from 'react';
import Toolbar from "../HomePage/components/ToolBar/ToolBar";
import Footer from "../HomePage/components/Footer/Footer";
import {
    Box,
    Grid,
    Typography,
    Paper,
    Button,
    InputBase,
    IconButton,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from '@mui/icons-material/Search';
import './projects.css';
import ListProjects from './ProjectList/ListProjects';


export default function Projects() {
    const [type, setType] = React.useState('');
    const handleChange = (event) => {
        setType(event.target.value);
    };

    return (<Box>
        <Toolbar mode="dark" page="project"></Toolbar>
        <Grid container pl={0} pr={0} id="toolBar">
            <Grid container mt={20}>
                <Grid item xl={12} xs={12}>
                    <Typography variant="h1" className="overview_title">
                        PROJECTS
                    </Typography>
                </Grid>
                <Grid item xl={12} xs={12} align="center" alignContent="center">
                    <Typography variant="body1">
                        <a href="/" className="color_home_overview">HOME</a> <NavigateNextIcon/>
                        <span className="overview_text-overview">PROJECTS</span>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>

        <Grid container p={0} mt={2}>
            {/*<Grid container item justifyContent="space-evenly">*/}
            {/*    <Grid item xs={2} >*/}
            {/*        <Paper className="form_search_projects"*/}
            {/*               component="form"*/}
            {/*               sx={{p: '2px 4px', display: 'flex', alignItems: 'center'}}>*/}

            {/*            <InputBase*/}
            {/*                sx={{ml: 1, flex: 1}}*/}
            {/*                placeholder="Search Projects"*/}
            {/*                inputProps={{'aria-label': 'search project'}}*/}
            {/*            />*/}
            {/*            <IconButton type="button" sx={{p: '10px'}} aria-label="search">*/}
            {/*                <SearchIcon/>*/}
            {/*            </IconButton>*/}
            {/*            <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>*/}

            {/*        </Paper>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={2} >*/}
            {/*        <FormControl fullWidth={true}>*/}
            {/*            <InputLabel id="demo-simple-select-label">Type</InputLabel>*/}
            {/*            <Select className="button_search_projects"*/}
            {/*                    labelId="demo-simple-select-label"*/}
            {/*                    id="demo-simple-select"*/}
            {/*                    value={type}*/}
            {/*                    label="Type"*/}
            {/*                    onChange={handleChange}*/}
            {/*            >*/}
            {/*                <MenuItem value={10}>Ankara</MenuItem>*/}
            {/*                <MenuItem value={20}>Stambul</MenuItem>*/}
            {/*                <MenuItem value={30}>Izmir</MenuItem>*/}
            {/*            </Select>*/}
            {/*        </FormControl>*/}
            {/*    </Grid>*/}

            {/*    <Grid item xs={2} >*/}
            {/*        <FormControl fullWidth={true}>*/}
            {/*            <InputLabel id="demo-simple-select-label">Status</InputLabel>*/}
            {/*            <Select className="button_search_projects"*/}
            {/*                    labelId="demo-simple-select-label"*/}
            {/*                    id="demo-simple-select"*/}
            {/*                    value={type}*/}
            {/*                    label="Type"*/}
            {/*                    onChange={handleChange}*/}
            {/*            >*/}
            {/*                <MenuItem value={10}>Active</MenuItem>*/}
            {/*                <MenuItem value={20}>Passive</MenuItem>*/}
            {/*            </Select>*/}
            {/*        </FormControl>*/}
            {/*    </Grid>*/}

            {/*    <Grid item xs={2} >*/}
            {/*        <FormControl fullWidth={true}>*/}
            {/*            <InputLabel id="demo-simple-select-label">Location</InputLabel>*/}
            {/*            <Select className="button_search_projects"*/}
            {/*                    labelId="demo-simple-select-label"*/}
            {/*                    id="demo-simple-select"*/}
            {/*                    value={type}*/}
            {/*                    label="Type"*/}
            {/*                    onChange={handleChange}*/}
            {/*            >*/}
            {/*                <MenuItem value={10}>Active</MenuItem>*/}
            {/*                <MenuItem value={20}>Passive</MenuItem>*/}
            {/*            </Select>*/}
            {/*        </FormControl>*/}
            {/*    </Grid>*/}

            {/*    <Grid item xs={2}>*/}
            {/*        <Button className="btn btn_color btn_search_projects">Search</Button>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Grid container p={{xs:1, md:5}} item mb={10}  justifyContent="center">

                <ListProjects />
                {/*<Grid container justifyContent="center" mt={8}>*/}
                {/*    <a className="btn btn_tran" data-animation="fadeInUp" data-delay=".6s">*/}
                {/*        <div className="btn_m">*/}
                {/*            <div className="btn_c">*/}
                {/*                <div className="btn_t1">Load More</div>*/}
                {/*                <div className="btn_t2">Load More</div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </a>*/}
                {/*</Grid>*/}
            </Grid>
        </Grid>

        <Footer/>
    </Box>)
}