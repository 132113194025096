import * as React from 'react';
import {Typography, Grid} from '@mui/material';
import "./we_are.css"
import ReactPlayer from "react-player";
import png1 from "../../../assets/images/home/we_are/1.png";
import png2 from "../../../assets/images/home/we_are/2.png";
import png3 from "../../../assets/images/home/we_are/3.png";
import png4 from "../../../assets/images/home/we_are/4.png";
import png5 from "../../../assets/images/home/we_are/5.png";
import png6 from "../../../assets/images/home/we_are/6.png";
import Property from '../components/Property/Property';




export default function WeAre(){
    return(
        <Grid  container mt={16} justifyContent="center" id="we_are" className="we_are_container">
            <Grid item xs={6}>
                <Typography variant="caption" id="title">
                    WHAT WE DO?
                </Typography>
            </Grid>

            <Grid item lg={6} mt={1} >
                <Typography variant="body1" className="we_are_30_years" align="flex-end">
                    We are at your service for all your investments
                </Typography>
            </Grid>

            <Grid item xs={12} lg={5} mt={6} >
                <Typography variant='body1' id="details">
                We deal with the purchase, rental and sale of homes and all kinds of real estate with our experienced work, 
                predicting what benefits the investments will provide, making suggestions to investors and trying to minimize 
                any risks that may arise. With our expert staff and experience, we offer you the most ideal solutions to be 
                successful in the field of investment.
                </Typography>
            </Grid>

            <Grid item xs={12} md={6} mt={10} >
                <a className="btn btn_tran" data-animation="fadeInUp" data-delay=".6s" href='#projects_blog'>
                    <div className="btn_m">
                        <div className="btn_c">
                            <div className="btn_t1">MORE ABOUT US</div>
                            <div className="btn_t2">MORE ABOUT US</div>
                        </div>
                    </div>
                </a>
            </Grid>

            <Grid container item p={0} xs={12} lg={10} mt={16} alignContent="center">
                <Grid item xs={2} className="div_image_we_are">
                    <img src={png1} alt={'page1'}/>
                </Grid>

                <Grid item xs={2} className="div_image_we_are">
                    <img src={png2} alt={'page2'}/>
                </Grid>

                <Grid item xs={2} className="div_image_we_are">
                    <img src={png3} alt={'page3'}/>
                </Grid>

                <Grid item xs={2} className="div_image_we_are">
                    <img src={png4} alt={'page4'}/>
                </Grid>

                <Grid item xs={2} className="div_image_we_are">
                    <img src={png5} alt={'page5'}/>
                </Grid>

                <Grid item xs={2} className="div_image_we_are">
                    <img src={png6} alt={'page6'}/>
                </Grid>
            </Grid>

            <Grid item xl={12}  mt={16} className="video_blog">
                <ReactPlayer  url="https://www.youtube.com/watch?v=3GPf0tyZxTc"
                              controls />
            </Grid>

            <Property />


        </Grid>
    )
}