import { Grid, TextField, MenuItem, InputLabel, FormControl, Select, FormControlLabel, Checkbox, Autocomplete } from "@mui/material";
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Controller, useFormContext } from "react-hook-form";
import FormError from "../../../../../../components/FormError/FormError";
import { is_english_text } from "../../../../../../../../validators/file_validators";
import React from 'react';

const CONSTR_OPTIONS = ['Metal', 'Cement', 'Concrete', 'Structure Steel', 'Carbon Fiber', 'Reinforced Steel', 'Steel Wire',
    'Bitumen', 'Adobe', 'Plastic Materials', 'Bamboo', 'Briquette', 'Tile', 'Soil - Sand - Clay', 'Aggregate - Gravel - Stone',
    'Brick', 'Polymer', 'Pine', 'Wood - Plank', 'Ceramic', 'Plastic', 'Polymer', 'Aluminum']

export default function Tourism({ project }) {
    const { register, formState: { errors }, control, setValue } = useFormContext()

    return (
        <Grid container item xs={12} spacing={2} p={0}>
            <Grid item xs={12} sm={6} md={3} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">
                        <React.Fragment>
                            Building Age <span>*</span>
                        </React.Fragment>
                    </InputLabel>
                    <Controller
                        name={'details.building_age'}
                        defaultValue={project?.details?.building_age}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Garden or Private Area"
                            >
                                <MenuItem value={'new_build'}>New Build</MenuItem>
                                <MenuItem value={'months_6_12'}>6-12 months</MenuItem>
                                <MenuItem value={'years_1_5'}>1-5 Years</MenuItem>
                                <MenuItem value={'years_5_10'}>5-10 Years</MenuItem>
                                <MenuItem value={'years_10_15'}>10-15 Years</MenuItem>
                                <MenuItem value={'years_15_20'}>15-20 Years</MenuItem>
                                <MenuItem value={'years_20_and_more'}>20+</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <FormError error={errors?.details?.building_age} errors={errors.details} name={'building_age'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">
                        <React.Fragment>
                            Infrastructure
                        </React.Fragment>
                    </InputLabel>
                    <Controller
                        name={'details.infrastructure'}
                        defaultValue={project?.details?.infrastructure}
                        control={control}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Garden or Private Area"
                            >
                                <MenuItem value={'fully_equipped'}>Fully Equipped</MenuItem>
                                <MenuItem value={'under_construction'}>Under Construction</MenuItem>
                                <MenuItem value={'unmade'}>Unmade</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6} className='advert_field'>
                <Controller
                    control={control}
                    name={'details.construction_materials'}
                    defaultValue={project?.details?.construction_materials}
                    render={({...field}) => (
                        <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-limit-tags"
                            size="small"
                            options={CONSTR_OPTIONS}
                            onChange={(e, v) => { setValue('details.construction_materials', v, {shouldDirty:true}) }}
                            defaultValue={project?.details?.construction_materials}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField {...params} className='tab_text_field' label="Construction Materials" />
                            )}
                            sx={{ width: 'auto' }}
                        />
                    )}
                />

            </Grid>

            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    defaultValue={project?.details?.number_of_rooms}
                    {...register('details.number_of_rooms', {
                        required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                        }
                    })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Number of Rooms<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.number_of_rooms} errors={errors.details} name={'number_of_rooms'} />

            </Grid>

            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    defaultValue={project?.details?.number_of_beds}
                    {...register('details.number_of_beds', {
                        required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                        }
                    })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Number of Beds<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.number_of_beds} errors={errors.details} name={'number_of_beds'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    defaultValue={project?.details?.number_of_floors}
                    {...register('details.number_of_floors', {
                        required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                        }
                    })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Number of Floors<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.number_of_floors} errors={errors.details} name={'number_of_floors'} />
            </Grid>

            <Grid item xs={12} md={6} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    defaultValue={project?.details?.number_of_terrace}
                    {...register('details.number_of_terrace', {
                        required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                        }
                    })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Number of Terrace Floor & Patio<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.number_of_terrace} errors={errors.details} name={'number_of_terrace'} />
            </Grid>

            <Grid item xs={12} md={6} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    size='small'
                    defaultValue={project?.details?.count_of_stars}
                    {...register('details.count_of_stars', {
                        valueAsNumber: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                        }
                    })}
                    fullWidth
                    id="outlined-required"
                    label="How Many Stars"
                />
                <FormError error={errors?.details?.count_of_stars} errors={errors.details} name={'count_of_stars'} />
            </Grid>

            <Grid item xs={12} md={6} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Heating Method</InputLabel>
                    <Controller
                        name={'details.heating_method'}
                        defaultValue={project?.details?.heating_method}
                        control={control}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Heating Method"
                            >
                                <MenuItem value={'central_heating'}>central heating</MenuItem>
                                <MenuItem value={'floor_heating'}>floor heating</MenuItem>
                                <MenuItem value={'air_conditioned'}>air conditioned</MenuItem>
                                <MenuItem value={'gas_or_water_heater'}>gas or water heater</MenuItem>
                                <MenuItem value={'solar_system'}>solar system</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">Ventilation Method</InputLabel>
                    <Controller
                        name={'details.ventilation_method'}
                        defaultValue={project?.details?.ventilation_method}
                        control={control}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Ventilation Method"
                            >
                                <MenuItem value={'air_extraction_components'}>air extraction components</MenuItem>
                                <MenuItem value={'natural_ventilation'}>natural ventilation</MenuItem>
                                <MenuItem value={'air_conditioner'}>air conditioner</MenuItem>
                                <MenuItem value={'auxiliary ventilation'}>auxiliary ventilation</MenuItem>
                                <MenuItem value={'range_hood_method'}>range hood method</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    defaultValue={project?.details?.ground_survey}
                    {...register('details.ground_survey', {
                        required: true,
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Ground Survey<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.ground_survey} errors={errors.details} name={'ground_survey'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    type="number"
                    defaultValue={project?.details?.number_of_pools}
                    {...register('details.number_of_pools', {
                        required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                        }
                    })}
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Number of Pools<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.number_of_pools} errors={errors.details} name={'number_of_pools'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    type="number"
                    defaultValue={project?.details?.number_of_buildings}
                    {...register('details.number_of_buildings', {
                        required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                        }
                    })}
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Number of Buildings<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.number_of_buildings} errors={errors.details} name={'number_of_buildings'} />
            </Grid>

            <Grid item xs={4} mt={2} className='advert_field'>
                <FormControlLabel
                    size='small'
                    className='tab_text_field checkbox_field form_checkbox'
                    control={
                        <React.Fragment >
                            <Controller
                                name={"details.airstrip"}
                                defaultValue={project?.details?.airstrip}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                        icon={<CropSquareRoundedIcon />}
                                        checkedIcon={<SquareRoundedIcon />}
                                        checked={value}
                                        onChange={(e) => onChange(e.target.checked)}
                                    />
                                )}
                            />
                        </React.Fragment>}
                    label="Airstrip"
                    labelPlacement="top"
                />
            </Grid>

        </Grid>
    )
}