import React from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";
import { api } from '../../../../../api/api';

const ITEM_HEIGHT = 48;


export default function MenuProjectOptions({project_id, project_name, setProgress, set_delete_dialog}) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (purpose) => {
        setAnchorEl(null);
        purpose === 'edit' && navigate(`/admin_panel/project_edit/${project_id}/`)
        if (purpose === 'download') {
          setProgress(true)
          api(`/api/projects/${project_id}/download_pdf/`).then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${project_name}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setProgress(false)
          })
        }
        purpose === 'delete' && set_delete_dialog(true)
    };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        sx={{'color': '#bd9a68'}}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4,
            width: '20ch',
          },
        }}
      >

          <MenuItem key={1} onClick={() => handleClose('edit')}>Edit</MenuItem>
          <MenuItem key={1} onClick={() => handleClose('delete')}>Delete</MenuItem>
          <MenuItem key={1} onClick={() => handleClose('download')}>Download PDF</MenuItem>

      </Menu>
    </div>
  );
}