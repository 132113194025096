import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import FormError from '../../../../../components/FormError/FormError';

const RealEstate = [
    ['house', 'House'], ['apartment_and_condo', 'Apartment & Condo'], ['villa_and_mansion', 'Villa & Mansion'], 
    ['residence_and_suit', 'Residence & Suit'], ['penthouse_and_loft', 'Penthouse & Loft'], 
    ['prefabricate_and_container', 'Prefabricate & Container'], ['tiny_house', 'Tiny House'],
    ['whole_building', 'Whole Building', 'ready_project', 'Ready Project']
]

const LandAndTerrain = [
    ['plot_for_sale', 'Plot for Sale'], ['farm_and_garden', 'Farm & Garden'], ['ready_project', 'Ready Project']
]

const TourismAndFacility = [
    ['hotel', 'Hotel'], ['holiday_village_and_facility', 'Holiday Village & Facility'],
    ['thermal_hotel_and_facility', 'Thermal Hotel & Facility'], ['boutique_places', 'Boutique Places'],
    ['camping_zone', 'Camping Zone'], ['theme_parks_and_entertainment', 'Theme Parks & Entertainment'],
    ['authentic_and_historic_places', 'Authentic & Historic Places'], ['natural_life_facility_and_park', 'Natural Life Facility & Park']
]

const Business = [
    ['plaza_and_business_center', 'Plaza & Business Center'], ['shopping_mall', 'Shopping Mall'],
    ['office_and_bureau', 'Office & Bureau'], ['shop_and_store', 'Shop & Store'],
    ['factory_and_warehouse', 'Factory & Warehouse'], ['entertainment_and_amusement', 'Entertainment & Amusement'],
    ['workshop_and_production_place', 'Workshop & Production Place'],
    ['cafe_bar_and_restaurant', 'Cafe Bar & Restaurant'], ['power_plants', 'Power Plants'],
    ['hospital_and_health_center', 'Hospital & Health Center'], ['gas_station', 'Gas Station'],
    ['other_places', 'Other Places']
]


export default function Category({ estate_type, setEstateType, project }) {
    const { formState: { errors }, control, clearErrors, unregister, watch, setValue } = useFormContext()
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function set_form_estate(v, category) {
        if (!project ) {

            if ((watch('general.estate_category') === 'land_and_terrain' || category === 'land_and_terrain') && (watch('general.estate_category') !== category)){
                unregister('general.state')
            }

            if (watch('general.estate_category') === 'land_and_terrain' && category === 'land_and_terrain'){
                let arr = ['plot_for_sale', 'ready_project']
                if (!(arr.includes(watch('general.estate_type')) && arr.includes(v))){
                    unregister('general.state')
                }
            }
            clearErrors('general.estate_type')
            setValue('general.estate_type', v)
            setValue('general.estate_category', category)
            setExpanded(false)

            setEstateType(v)
            unregister('details')
            unregister('features')
    }
    }

    useEffect(() => {
        if (project) {
            setValue('general.estate_type', project.estate_type)
            setValue('general.estate_category', project.estate_category)
            setEstateType(project.estate_type)
        }
        else {
            setEstateType(watch('general.estate_type'))
        }
    }, [project])

    return (
        <Grid container item xs={12} lg={2.5} xl={3} spacing={2} sx={{ p: { xs: 0, md: 1.5 } }}>
            <Grid item xs={12}>
                <Typography variant='h5' color='#fff'>
                    Choose Category <span className='choose_category_span'>*</span>
                </Typography>
            </Grid>

            <Grid item xs={12} className='wrap_accordion'>
                <Controller name='general.estate_type' control={control} rules={{ required: 'Please choose one of the options.' }}

                    render={() => (
                        <React.Fragment>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    className={RealEstate.some((el) => el.includes(estate_type)) ? 'summary_chosen' : ''}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography sx={{ flexShrink: 0 }}>
                                        Real Estate
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {RealEstate.map((type, index) => (
                                        <ul key={index} className={estate_type === type[0] ? 'ul_accordion ul_checked' : 'ul_accordion'} onClick={() => set_form_estate(type[0], 'real_estate')}>{type[1]}</ul>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    className={LandAndTerrain.some((el) => el.includes(estate_type)) ? 'summary_chosen' : ''}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography sx={{ flexShrink: 0 }}>Land & Terrain</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {LandAndTerrain.map((type, index) => (
                                        <ul key={index} className={estate_type === type[0] ? 'ul_accordion ul_checked' : 'ul_accordion'} onClick={() => set_form_estate(type[0], 'land_and_terrain')}>{type[1]}</ul>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    className={TourismAndFacility.some((el) => el.includes(estate_type)) && 'summary_chosen'}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography sx={{ flexShrink: 0 }}>
                                        Tourism & Facility
                                    </Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    {TourismAndFacility.map((type, index) => (
                                        <ul key={index} className={estate_type === type[0] ? 'ul_accordion ul_checked' : 'ul_accordion'} onClick={() => set_form_estate(type[0], 'tourism_and_facility')}>{type[1]}</ul>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                    className={Business.some((el) => el.includes(estate_type)) ? 'summary_chosen' : ''}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Typography sx={{ flexShrink: 0 }}>Business</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {Business.map((type, index) => (
                                        <ul key={index} className={estate_type === type[0] ? 'ul_accordion ul_checked' : 'ul_accordion'} onClick={() => set_form_estate(type[0], 'business')}>{type[1]}</ul>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </React.Fragment>
                    )}
                />
                <FormError error={errors?.general?.estate_type} errors={errors.general} name={'estate_type'}/>


            </Grid>
        </Grid>
    )
}