import * as React from 'react';
import { Box, Grid, Typography } from "@mui/material";
import ToolBar from "../HomePage/components/ToolBar/ToolBar";
import Footer from "../HomePage/components/Footer/Footer";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./contact.css";
import QuickInquiry from "../HomePage/components/QuickInquiry/quick_inquiry";

export default function Contact() {
    return (
        <Box>
            <ToolBar mode='dark' page='contact' />
            <Grid container pl={0} pr={0}>
                <Grid container mt={20}>
                    <Grid item xl={12} xs={12}>
                        <Typography variant="h1" className="overview_title">
                            CONTACT
                        </Typography>
                    </Grid>
                    <Grid item xl={12} xs={12} align="center" alignContent="center">
                        <Typography variant="body1">
                            <a href="/" className="color_home_overview">HOME</a> <NavigateNextIcon />
                            <span className="overview_text-overview">CONTACT</span>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container justifyContent="center" mt={10}>
                    <Grid container item lg={5}>
                        <Grid item xs={12} align="center" className="title_contact">
                            MAIN OFFICE</Grid>
                        <Grid item xs={12} align="center" className="location_contact" mt={1}>
                            Mustafa Kemal Mah. Dumlupinar Blv.
                            Mahall Ankara, 274/2, Blok C2 No: 129
                            Cankaya, Ankara 06530</Grid>
                        <Grid item xs={12} align="center" className="contact_data">
                            info@go4invest.com
                        </Grid>
                        <Grid item xs={12} align="center" className="contact_data">
                            +90 (312) 999 67 09
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container justifyContent="center" mt={10}>
                    <iframe className="frame_google_maps" style={{ border: 'none', borderRadius: '5px' }}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.4738946566085!2d32.750502776034985!3d39.90840988642059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3386762728621%3A0x88fbbb6e491e6502!2sMahall%20Ankara!5e0!3m2!1sru!2sby!4v1697114014641!5m2!1sru!2sby" allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </Grid>

                <Grid container justifyContent="center">
                    <QuickInquiry />
                </Grid>

            </Grid>
            <Footer />

        </Box>

    )
}