import { useForm } from "react-hook-form"
import { Card, CardContent, CardHeader, Grid, TextField, CardActions, Button, Snackbar, Alert } from "@mui/material"
import FormError from '../../components/FormError/FormError'
import { api } from "../../../../api/api"
import * as React from 'react'
import { Dropzone } from "@dropzone-ui/react";
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';


export default function UserForm({ user_info }) {
    const [open, setOpen] = React.useState(false);
    const { register, setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            email: user_info.email,
            first_name: user_info.first_name,
            last_name: user_info.last_name,
            phone_number: user_info.phone_number
        }
    })

    const handleSave = async (form_data) => {
        await api(`/auth/user/${user_info.pk}/`, 'PATCH', form_data, false, 
        { headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
            setOpen(true)
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    return (
        <React.Fragment>
            <Card className="card_profile">
                <CardHeader
                    title="User Information"
                />
                <form onSubmit={handleSubmit(handleSave)}>

                    <CardContent>
                        <Grid container p={1} justifyContent='space-evenly' spacing={1} alignItems='flex-start'>

                            <Grid item container xs={12} md={8} p={0} >
                                <Grid item xs={12} md={6} mb={2} pr={.5}>
                                    <TextField sx={{ width: '100%' }} size='small' className="text_field_profile"
                                        label="First Name"
                                        placeholder="First Name"
                                        multiline maxRows={4}
                                        {...register("first_name", {required: true})}
                                    />
                                    <FormError error={errors?.first_name}/>
                                </Grid>

                                <Grid item xs={12} md={6} mb={2} pl={.5}>
                                    <TextField sx={{ width: '100%' }} size='small' className="text_field_profile"
                                        label="Last Name"
                                        placeholder="Last Name"
                                        multiline maxRows={4}
                                        {...register("last_name", {required: true})}
                                    />
                                    <FormError error={errors?.last_name}/>
                                </Grid>

                                <Grid item xs={12} mb={2}>
                                    <TextField sx={{ width: '100%' }} size='small' className="text_field_profile"
                                        label="Phone Number"
                                        placeholder="Phone Number"
                                        multiline maxRows={4}
                                        {...register("phone_number", {required: true})}
                                    />
                                    <FormError error={errors?.phone_number}/>
                                </Grid>

                            </Grid>

                            <Grid container item xs={12} md={6} lg={4} p={0} alignItems='flex-start'>
                                <Grid item xs={12} >
                                    <Dropzone header={false} footer={false}
                                        onChange={(file) => setValue('avatar', file[0].file)}
                                        label={<PersonAddAltRoundedIcon fontSize='xxx-large' />} minHeight='50px'/>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} mb={1}>
                                <TextField sx={{ width: '100%' }} size='small' className="text_field_profile"
                                    label="Email"
                                    placeholder="Email"
                                    disabled
                                    value={user_info.email}
                                    multiline maxRows={4}
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <TextField sx={{ width: '100%' }} size='small' className="text_field_profile"
                                    label="Country"
                                    placeholder="Country"
                                    multiline maxRows={4}
                                    defaultValue={user_info.country}
                                    {...register("country")}
                                />
                            </Grid>

                        </Grid>

                    </CardContent>

                    <CardActions>
                        <Grid container justifyContent='flex-end' >
                            <Grid item >
                                <Button className='btn_profile_save' type="submit" variant="contained"> Save </Button>
                            </Grid>
                        </Grid>

                    </CardActions>
                </form>
            </Card>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity="success">User information was updated!</Alert>
            </Snackbar>
        </React.Fragment>
    )
}