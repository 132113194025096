import * as React from 'react';
import {Link, Grid, Button, Typography, TextField} from '@mui/material';
import "./footer.css"


export default function Footer(){
    return (
        <Grid container p={0} className="footer_container" justifyContent="center">

            <Grid container item xl={12} sm={12} pt={10} alignItems="flex-start">
                <Grid item lg={2} xs={12} align="center" mb={3} p={0}>
                    <Typography className="reland_text" variant="body1">RELAND</Typography>
                </Grid>

                <Grid container item xs={6} lg={3} p={0} justifyContent='center'>
                    <Typography className="footer_title_text" variant="body1">CONTACT</Typography>

                    <Grid item mt={4} xs={12}>
                        <Typography className="footer_small_text" variant="body1" align='center'>
                        Mustafa Kemal Mah. Dumlupinar Blvd. Mahall Ankara, 274/2, Blok C2 No: 129
                        </Typography>
                        <Typography className="footer_small_text" variant="body1" align='center'>
                            Cankaya, Ankara 06530
                        </Typography>
                    </Grid>

                    <Grid item mt={2} xs={12}>
                        <Typography className="footer_small_text" variant="body1" align='center'>
                            info@go4invest.com
                        </Typography>
                        <Typography className="footer_small_text" variant="body1" align='center'>
                            +90 (312) 999 67 09
                        </Typography>
                    </Grid>
                </Grid>


                <Grid container item xs={6} lg={3} p={0} className="footer_useful_links_div" justifyContent='center'>

                    <Typography className="footer_title_text" variant="body1">USEFUL LINKS</Typography>

                    <Grid item mt={4} xl={12} sm={12}>
                        <Grid item align='center'>
                            <Link href='/contact' className="footer_small_text ft_lnk" >About Us</Link>
                        </Grid>

                        <Grid item mt={1} align='center'>
                            <Link href='/blog' className="footer_small_text ft_lnk">Blogs</Link>
                        </Grid>

                        <Grid item mt={1} align='center'>
                            <Link href='/projects' className="footer_small_text ft_lnk">Projects</Link>
                        </Grid>


                        <Grid item mt={1} align='center'>
                            <Link className="footer_small_text ft_lnk">Privacy Policy</Link>
                        </Grid>
                    </Grid>

                </Grid>


                <Grid container item xs={12} lg={3} p={0} className="footer_newsletter_div" justifyContent='center'>
                    <Typography className="footer_title_text" variant="body1">NEWSLETTER</Typography>

                    <Grid container item mt={2} xs={12} pl={0} wrap="nowrap" p={0} justifyContent="center">
                        <Grid item className="item_textfield">
                            <TextField id="outlined-basic footer_text_field" label="Email address..." variant="outlined"/>
                        </Grid>

                        <Grid item >
                            <Button className="btn_footer">
                                <svg className="svg_footer" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                     fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M8.2345 2.63462C8.38452 2.48464 8.58797 2.40039 8.8001 2.40039C9.01223 2.40039 9.21567 2.48464 9.3657 2.63462L14.1657 7.43462C14.3157 7.58464 14.3999 7.78809 14.3999 8.00022C14.3999 8.21235 14.3157 8.4158 14.1657 8.56582L9.3657 13.3658C9.21482 13.5115 9.01273 13.5922 8.80298 13.5904C8.59322 13.5885 8.39257 13.5044 8.24424 13.3561C8.09592 13.2077 8.01178 13.0071 8.00996 12.7973C8.00814 12.5876 8.08877 12.3855 8.2345 12.2346L11.6689 8.80022H2.4001C2.18792 8.80022 1.98444 8.71593 1.83441 8.5659C1.68438 8.41588 1.6001 8.21239 1.6001 8.00022C1.6001 7.78805 1.68438 7.58456 1.83441 7.43453C1.98444 7.2845 2.18792 7.20022 2.4001 7.20022H11.6689L8.2345 3.76582C8.08452 3.6158 8.00027 3.41235 8.00027 3.20022C8.00027 2.98809 8.08452 2.78464 8.2345 2.63462Z"
                                          fill="#FBFBFB"/>
                                </svg>
                            </Button>
                        </Grid>
                    </Grid>


                </Grid>
            </Grid>

            <Grid container mt={10} mb={10} justifyContent="center">
                <Grid item xs={12} mb={4} className="copyright">
                    © 2024 Go4invest LLC All Rights Reserved |
                </Grid>

                <Grid container item justifyContent="center" >

                    <Grid item m={1}>
                        <a className="icon_footer" href="#head_of_page">
                           <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <path fill="#bd9a68" d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"/>  </svg>
                        </a>

                    </Grid>

                    <Grid item m={1}>
                        <a className="icon_footer" href="#head_of_page">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"

                                  viewBox="0 0 16 16">
                                <path fill="#bd9a68"
                                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                            </svg>
                        </a>
                    </Grid>

                    <Grid item m={1}>
                        <a className="icon_footer" href="#head_of_page">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                  viewBox="0 0 16 16">
                                <path fill="#bd9a68"
                                    d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                            </svg>
                        </a>
                    </Grid>

                    <Grid item m={1}>
                        <a className="icon_footer" href="#head_of_page">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                 <path fill="#bd9a68" fillRule="nonzero" d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"/>
                            </svg>
                        </a>
                    </Grid>


                </Grid>
            </Grid>
        </Grid>
    )
}