import { Grid, IconButton, Avatar, Menu, Typography, Button, MenuItem, Link } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import './app_bar.css'
import logo from '../../../../assets/images/basic/logo.png'
import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useEffect } from 'react';
import { api, removeStorageAndGoToLogin } from '../../../../api/api';


export default function ButtonAppBar({ funSw, isOpen }) {
    const navigate = useNavigate()
    const [userInfo, setUserInfo] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {

        async function fetchUserData() {
            await api(`/auth/user/`).then((res) => {
                setUserInfo(res.data)
            })
        }
        fetchUserData()
    }, [])

    const logoutHandle = async () => {

        await api(`/auth/logout/`, 'POST', { refresh: localStorage.getItem('refresh_token') },)
            .then((response) => {
                removeStorageAndGoToLogin()
            })
            .catch((err) => {

            })
    }

    return (
        <Grid container className="nav_bar_ap" justifyContent="space-between" pb={0} alignItems='center' sx={{ zIndex: 2 }}>

            <Grid container item p={0} xs={6} md={4} lg={3} alignItems="center">
                <Grid container item xs={8} sm={6} lg={5} pl={2} align='start'>
                    <Link href="/" sx={{ cursor: 'pointer' }}>
                        <img src={logo} width="71px" alt='logo' />
                    </Link>
                </Grid>

                <Grid item xs={4} sm={6} lg={6}>
                    <IconButton
                        onClick={() => funSw(!isOpen)}
                        className="switch_app_bar_btn"
                        size="large"
                        edge="start"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid item xs={2} align="end">
                <IconButton className='profile_btn' onClick={handleClick}>
                    <Avatar alt="Root" src="/static/images/avatar/1.jpg" />

                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="tabler-icon tabler-icon-settings"><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path></svg>

                </IconButton>

                <Menu
                    
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <Grid container p={2} sx={{ width: '300px !important' }}>
                        <Grid item xs={12}>
                            <Typography className='hello_user_app_bar'>
                                <span>Hello,</span> {userInfo && userInfo.first_name}!
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className='user_position_app_bar'>
                            <Typography>{userInfo && userInfo.is_staff ? 'Root Admin' : 'New User'}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <hr />
                        </Grid>

                        <Grid container className='tariff_blog_app_bar'>
                            <Grid item xs={12}>
                                <Typography>Upgrade you plan</Typography>
                            </Grid>

                            <Grid item xs={6} mt={2}>
                                <Typography>
                                    {userInfo && `Your tariff is ${userInfo.tariff}!`}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Button className='go_premium' onClick={() => navigate('/admin_panel/pricing')}>Go Premium</Button>
                            </Grid>

                            <span className='el_tariff1' />
                            <span className='el_tariff2' />
                        </Grid>

                        <Grid item xs={12} mt={2} className='btn_settings_app_bar'>
                            <MenuItem onClick={() => {
                                handleClose()
                                navigate('/admin_panel/profile')
                                }}>
                                <svg style={{ 'marginRight': '10px' }} xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke='currentColor' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" class="tabler-icon tabler-icon-settings"><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path></svg>
                                Account settings
                            </MenuItem>
                        </Grid>

                        <Grid item xs={12} className='btn_settings_app_bar'>
                            <MenuItem onClick={logoutHandle}>
                                <svg style={{ 'marginRight': '10px' }} xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" class="tabler-icon tabler-icon-logout"><path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path><path d="M9 12h12l-3 -3"></path><path d="M18 15l3 -3"></path></svg>
                                Logout
                            </MenuItem>
                        </Grid>

                    </Grid>


                </Menu>
            </Grid>
        </Grid>
    );
}