import {
    Grid, Paper, Avatar, Typography, Button, Rating, TableContainer, Table, TableBody,
    TableRow, TableCell
} from '@mui/material'
import PinDropIcon from '@mui/icons-material/PinDrop';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import DiamondRoundedIcon from '@mui/icons-material/DiamondRounded';
import StarPurple500OutlinedIcon from '@mui/icons-material/StarPurple500Outlined';
import { useEffect, useState } from 'react';
import { api } from '../../../../api/api'
import './profile_tab.css'

export default function ProfileTab() {
    const [userInfo, setUserInfo] = useState(null)

    useEffect(() => {
        api('/auth/user/').then((res) => {
            setUserInfo(res.data)
        })
    }, [])

    return (
        <Grid container xs={12} p={1} mt={3} alignItems="flex-start" justifyContent='space-evenly'>

            <Grid container item p={0} xs={12} lg={4} xl={3} sx={{mr: {xs: 0, lg: 2}}} mb={2} component={Paper} className='container_profile' justifyContent='center'>
                <Grid container item xs={12} justifyContent='space-between' alignItems='center' p={2} >
                    <Grid item >
                        <Avatar sx={{ width: 80, height: 80 }} src={userInfo?.avatar} variant="rounded" alt="Remy Sharp" />
                    </Grid>

                    <Grid item>
                        <Typography p={0} color='white'>{userInfo?.first_name} {userInfo?.last_name}</Typography>
                        <Typography variant='caption' color='gray' p={0}>
                            {userInfo?.have_access_like_investor && 'Investor'}
                            {userInfo?.have_access_like_realtor && 'Realtor'}
                            {!userInfo?.have_access_like_realtor && !userInfo?.have_access_like_investor && 'Admin'}
                        </Typography>
                    </Grid>

                    <Grid item >
                        <Button variant='contained' size='small' className='btn_tariff'>
                            <DiamondRoundedIcon /> 
                            {userInfo?.tariff === 'full_access' && 'full access'}
                            {userInfo?.tariff === 'trial_realtor' && 'trial'}
                            {(userInfo?.tariff === 'standard_realtor' || userInfo?.tariff === 'standard_investor') && 'standard'}
                            {(userInfo?.tariff === 'premium_realtor' || userInfo?.tariff === 'premium_investor') && 'premium'}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent='space-between' alignItems='center' pr={5} pt={2} pl={5}>

                    <Grid item >
                        <PinDropIcon className='icon_profile' />
                    </Grid>
                    <Grid item >
                        <Typography color='gray'>{userInfo?.company.address}</Typography>
                    </Grid>
                    <Grid item xs={12} className='br_block'>
                        <br />
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent='space-between' alignItems='center' pr={5} pl={5}>
                    <Grid item >
                        <EmailRoundedIcon className='icon_profile' />
                    </Grid>
                    <Grid item >
                        <Typography color='gray'>{userInfo?.email}</Typography>
                    </Grid>
                    <Grid item xs={12} className='br_block'>
                        <br />
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent='space-between' alignItems='center' pr={5} pl={5}>
                    <Grid item >
                        <LocalPhoneRoundedIcon className='icon_profile' />
                    </Grid>
                    <Grid item >
                        <Typography color='gray'>{userInfo?.phone_number}</Typography>
                    </Grid>
                    <Grid item xs={12} className='br_block'>
                        <br />
                    </Grid>
                </Grid>

                <Grid container item xs={10} justifyContent='center' className='desc_profile_container' alignItems='center' pr={2} pt={2} pl={2}>
                    <Grid item align='center' p={0} >
                        <Typography color='white' variant='h4'>{userInfo?.count_project}</Typography>
                        <Typography color='gray' variant='caption' margin={0}>Projects</Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={3} justifyContent='center' alignItems='center' pr={2} pt={2} pl={2} className='ratio_profile_container'>
                    <Grid item align='center' p={0} >
                        <Rating readOnly name="size-small" defaultValue={2} size="small"
                            emptyIcon={<StarPurple500OutlinedIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                        <Typography variant='caption' color='white'>Total Ratio</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item p={0} xs={12} lg={7.8} xl={8.8} component={Paper} className='container_profile_about'>

                <Grid item xs={12} p={2} className='about_info_container'>
                    <Typography variant='h4' color='white'>About & Information</Typography>
                </Grid>

                <Grid item p={2} xs={12}>
                    <Typography variant='body1' color='white'>
                        In elit urna, posuere ac consequat sit amet, sollicitudin vulputate dolor.
                        Sed non purus mattis, cursus dui eget, venenatis tellus. Nulla aliquet ultrices consequat.
                        Donec et mattis tellus. Ut vitae scelerisque nisl. Sed ornare interdum neque nec aliquet.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={7} mb={2}>
                    <TableContainer >
                        <Table  aria-label="simple table" className='table_profile'>
                            <TableBody>
                                <TableRow
                                    key={1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left" pr={0}>Company</TableCell>
                                    <TableCell align="center">:</TableCell>
                                    <TableCell align="left">{userInfo?.company.name} {userInfo?.company.address}</TableCell>

                                </TableRow>

                                <TableRow
                                    key={1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left" pr={0}>Contact</TableCell>
                                    <TableCell align="center">:</TableCell>
                                    <TableCell align="left">Email, Phone</TableCell>

                                </TableRow>

                                <TableRow
                                    key={1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left" pr={0}>Country</TableCell>
                                    <TableCell align="center">:</TableCell>
                                    <TableCell align="left">{userInfo?.country}</TableCell>

                                </TableRow>

                                <TableRow
                                    key={1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left" pr={0}>Website</TableCell>
                                    <TableCell align="center">:</TableCell>
                                    <TableCell align="left">{userInfo?.company.website}</TableCell>

                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} component={Paper}></Grid>

            </Grid>
        </Grid>
    )
}