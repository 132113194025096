import * as React from 'react'
import { Grid } from "@mui/material";
import './card.css'
import '../../css/admin_panel.css'
import { useDispatch, useSelector } from "react-redux";
import { selectAllProjects, selectStatusProjects, fetchProjects } from "../../../../features/projects/projectsSlice";
import { useEffect, useState } from "react";
import SearchPanel from '../components/SearchPanel/SearchPanel'
import { useNavigate } from "react-router-dom";
import ProjectCard from '../components/ProjectCard/ProjectCard';
import { setProjectId } from '../../../../features/projects/projectsSlice'; 


export default function ListAdminPanelProjects() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const status = useSelector(selectStatusProjects)
    const [projects, setProjects] = useState(null)
    const ft_projects = useSelector(selectAllProjects)

    const init_filter_data = {
        apartment: true, hotel: true, land: true,
        price_from: 0, price_to: 9999999999999,
        created_status: true, confirmed_status: true,
        country: 'default', region: 'default', city: 'default'
    }
    const [estate, setEstate] = useState(init_filter_data)

    const [search, setSearch] = useState('')

    function handleClearFilter() {
        setEstate(init_filter_data)
    }

    useEffect(() => {
        if (status === 'idle'){
            dispatch(fetchProjects())
        }
    }, [])


    useEffect(() => {
        let data = {
            'type_estate': [
                estate.apartment && 'apartment',
                estate.hotel && 'hotel',
                estate.land && 'land',
            ],
            'status': [
                estate.created_status && 'created',
                estate.confirmed_status && 'confirmed',
            ]
        }

        let new_pr_list = ft_projects.filter((project) => data.type_estate.includes(project.type_estate)
            && (project.price >= estate.price_from && project.price <= estate.price_to)
            && (data.status.includes(project.status))
            && (estate.country === 'default' ? project.country : project.country === estate.country)
            && (estate.region === 'default' ? project.region : project.region === estate.region)
            && (estate.city === 'default' ? project.city : project.city === estate.city)
        )
        if (search !== '') {
            new_pr_list = new_pr_list.filter((project) => project.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
        }

        if (status !== 'idle'){
            setProjects(ft_projects)
        }
        
    }, [ft_projects, dispatch, estate, search])


    function selectProject(id) {
        dispatch(setProjectId({project_id: id}))
        navigate(`/admin_panel/project_view/${id}/`)
    }

    function get_card_preview(project) {
        if (project?.media.length === 0) {
            return null
        }

        let first_pos = project?.media.find(item => item.position === 1)
        if (first_pos){
            return first_pos.file
        }
        return project?.media[0].file
    }

    return (

        <Grid container p={0} alignItems="flex-start" justifyContent='space-between'>
            <SearchPanel setSearch={setSearch} />



            {projects === null 
                ? <Grid></Grid>
                : (
                    <Grid container item p={0} pl={2} pr={2} xs={12} justifyContent='flex-start'
                    className={projects?.length === 0 && 'no_product'}>
                    {projects?.map((project) => {
                        return (
                            <Grid container item p={0} onClick={() => selectProject(project.id)} sx={{cursor: 'pointer', maxWidth: '350px'}} alignSelf={'normal'}>
                                <ProjectCard content={project} location={project.location}
                                    image_preview={get_card_preview(project)} details={project.details}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
                )
            }

        
        </Grid>
    )
}