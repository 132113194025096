import { Grid, Paper, Button } from '@mui/material';
import OverviewSlider from './ui/Slider';
import GeneralInfo from './ui/GeneralInfo';
import Preview from './ui/Preview';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FeaturesInfo } from './ui/FeaturesInfo';


export default function Overview({ activeStep, setActiveStep, setCompleted, project, purpose }) {
    const {resetField, watch, trigger, formState: {dirtyFields} } = useFormContext()
    const [drawings, setDrawings] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);

    const check_for_next = () => {
        trigger()
        setActiveStep(activeStep + 1)
        setCompleted({ 0: true, 1: true, 2: true, 3: true })
    }

    const check_for_prev = () => {
        setActiveStep(activeStep - 1)
        setCompleted({ 0: true, 1: true, 2: true })
    }

    useEffect(() => {
        let previews = []
        if (project) {
            Object.keys(project?.media).forEach((key) => {
                previews.push(
                    {
                        'file' : project?.media[key].file,
                        'type' : project?.media[key].type.slice(0, 5),
                        'position':  project?.media[key].position
                    })
            })
        }
 
        if (watch('media') && dirtyFields?.media) {
            let files = Array.from(watch('media'));
            files.map(file => (file.type.slice(0, 5) === 'image' || file.type.slice(0, 5) === 'video') 
                && previews.push(
                    {
                        'file': URL.createObjectURL(file?.file),
                        'type': file.type.slice(0, 5),
                        'position': file.position
                    }
            ));
        }

        setImagePreviews(previews);
    }, [watch('media'), project?.media]);

    useEffect(() => {
        let previews =[]
        if (project) {
            Object.keys(project?.drawings).forEach((key) => {
                previews.push([project?.drawings[key].drawing, key])
            })
        }

        if (watch('drawings') && dirtyFields?.drawings) {
            let files = Array.from(watch('drawings'));
            files.map((file, index) => file?.type?.slice(0, 5) === 'image' && previews.push([URL.createObjectURL(file?.file), index]));
        }
        setDrawings(previews);
    }, [watch('drawings'), project?.drawings]);

    useEffect(() => {
        let previews =[]
        if (project) {
            Object.keys(project?.certificates).forEach((key) => {
                previews.push([project?.certificates[key].certificate, key])
            })
        }

        if (watch('certificates') && dirtyFields?.certificates) {
            let files = Array.from(watch('certificates'));
            files.map((file, index) => previews.push([URL.createObjectURL(file?.file), index]));
        }
        setCertificates(previews)
                
    }, [watch('certificates'), project?.certificates]);


    const delete_draw = (delete_all, el) => {
        let watch_drawings = watch('drawings')
        if (!delete_all) {
            watch_drawings.splice(el, 1)
            resetField('drawings', {defaultValue: watch_drawings, keepDirty:true})
        }
        else {
            resetField('drawings', {keepDirty:false})
        }
    }

    const delete_cert = (delete_all, el) => {
        let watch_certificates = watch('certificates')
        if (!delete_all) {
            watch_certificates.splice(el, 1)
            resetField('certificates', {defaultValue: watch_certificates, keepDirty:true})
        }
        else {
            resetField('certificates', {keepDirty:false})
        }
    }

    return (
        <Grid container p={2} mt={2} component={Paper}
            className={activeStep !== 3 ? 'wrap_tab_container display_none' : 'wrap_tab_container'} alignItems='flex-start'>
            <Grid item xs={12} md={5}>
                <OverviewSlider imagePreviews={imagePreviews} purpose={purpose} project={project}/>
            </Grid>
            <Grid item xs={12} md={3.5}>
                <GeneralInfo
                    drawings={drawings} certificates={certificates} general={watch('general')} project={project}
                    details={watch('details')} location={watch('location')} purpose={purpose} delete_draw={delete_draw} delete_cert={delete_cert}
                />
            </Grid>
            <Grid item xs={12} md={3.5} p={2}>
                <Preview />
            </Grid>

            <Grid item xs={12}>
                <FeaturesInfo features={watch('features')} />
            </Grid>

            <Grid container item xs={12} justifyContent={'space-between'}>
                <Button onClick={check_for_prev} className='next_btn'>PREV</Button>
                <Button onClick={check_for_next} className='next_btn'>NEXT</Button>
            </Grid>
        </Grid>
    )

}