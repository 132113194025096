import React from "react";
import Slider from "react-slick";
import {Grid} from "@mui/material";

export default function SimpleSlider({images}) {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "250px",
        accessibility: false,
        slidesToShow: 1,
        speed: 500,
        arrows:false
    };


    const slider_content = images.map((image) => (
        <Grid container p={0} className="blog_slider">
            <img className="img_slider" src={image.image} alt="pic"/>
        </Grid>
    ))

    return (<Slider {...settings}>
        {slider_content}
    </Slider>);
}