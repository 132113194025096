import { Grid, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox } from "@mui/material";
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useFormContext } from "react-hook-form";
import React from 'react';

const FEATURES = [
    ['west', 'West'],
    ['east', 'East'],
    ['north', 'North'],
    ['south', 'South']
]

export default function Facades({project}) {
    const { control } = useFormContext()

    return (
        <Grid container item xs={12} p={0} alignSelf='center'>
            <Accordion sx={{ width: '100%' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    Facades
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container p={0} mb={2}>
                        {FEATURES.map((feat) => (
                            <Grid item xs={3} align='center'>
                                <FormControlLabel
                                    size='small'
                                    className='tab_text_field checkbox_field'
                                    control={
                                        <React.Fragment >
                                            <Controller
                                                name={`features.facades_feat.${feat[0]}`}
                                                defaultValue={project?.details?.facades_feat?.[feat[0]]}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <Checkbox
                                                        icon={<CropSquareRoundedIcon />}
                                                        checkedIcon={<SquareRoundedIcon />}
                                                        checked={value}
                                                        onChange={(e) => onChange(e.target.checked)}
                                                    />
                                                )}
                                            />
                                        </React.Fragment>}
                                    label={feat[1]}
                                    labelPlacement="top"
                                />
                            </Grid>
                        ))}

                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}