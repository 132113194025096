import * as React from 'react'
import { Grid, Button, InputBase, Paper, IconButton } from '@mui/material'
import './search_panel.css'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useNavigate } from "react-router-dom";
import FilterBlog from '../FilterBlog/FilterBlog';
import { useState } from 'react';


export default function SearchPanel({ setSearch, noAuth = false }) {
    const navigate = useNavigate()
    const [filter, setFilter] = useState(false)


    return (
        <Grid container className="search_filter_panel_container" mb={2} p={1} alignItems='center' justifyContent="center">
            <Grid item xs={12} sm={8} md={8} lg={7} align='center' sx={{ 'border': '1px solid #c9c9c9', 'border-radius': '10px' }} mr={1} p={0}>
                <Paper component="form" className='paper_search_filter'>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                    <InputBase sx={{ width: {xs:'85%', lg: '90%'} }} onChange={(e) => setSearch(e.target.value)}
                        size="small" className="standard-basic" placeholder="Search Project" />
                </Paper>


            </Grid>


            <Grid container item p={0} xs={4} sm={3} md={2} lg={1.5} alignItems='center'>
                <Grid item xs={2}  align="center">
                    <p className="vertical_line">|</p>
                </Grid>

                <Grid item xs={8} align="center">
                    <Button className="filter_button" onClick={() => setFilter(!filter)}><FilterAltIcon sx={{ 'font-size': '18px', 'text-transform': 'none' }} />Filter</Button>
                </Grid>

                <Grid item xs={2}  align="center">
                    <p className="vertical_line">|</p>
                </Grid>
                
            </Grid>


            {/*<Grid item xs={2} pl={1}>*/}
            {/*    <p >Sort by:</p>*/}
            {/*</Grid>*/}
            {!noAuth &&
                <Grid item  sx={{mr:{xs:1, lg:0}}}>
                    <IconButton item className="add_btn" onClick={() => navigate('/admin_panel/add_project')}
                    >
                        <AddIcon />
                    </IconButton>
                </Grid>
            }
            {/* <FilterBlog /> */}

        </Grid>
    )
}