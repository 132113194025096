import * as React from "react";
import {Box, Grid, Typography} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import png1 from "../../../assets/images/home/latest_news/1.png";
import png2 from "../../../assets/images/home/latest_news/2.png";
import png3 from "../../../assets/images/home/latest_news/3.png";
import png4 from "../../../assets/images/home/latest_news/4.png";
import png5 from "../../../assets/images/home/latest_news/5.png";
import png6 from "../../../assets/images/home/latest_news/6.png";
import png7 from "../../../assets/images/home/latest_news/7.png";
import png8 from "../../../assets/images/home/latest_news/8.png";
import png9 from "../../../assets/images/home/latest_news/9.png";
import Footer from "../components/Footer/Footer";
import ToolBar from "../components/ToolBar/ToolBar";
import "./blog.css"


export default function Blog() {
    return (
        <Box>
            <ToolBar mode='dark' page='blog'/>
            <Grid container pl={0} pr={0} justifyContent="center">

                <Grid container mt={20}>
                    <Grid item xl={12} xs={12}>
                        <Typography variant="h1" className="overview_title">
                            BLOG
                        </Typography>
                    </Grid>
                    <Grid item xl={12} xs={12} align="center" alignContent="center">
                        <Typography variant="body1">
                            <a href="/" className="color_home_overview">HOME</a> <NavigateNextIcon/>
                            <span className="overview_text-overview">BLOG</span>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid mt={8} mb={8} container p={0} item xl={10} sm={10} alignItems="flex-start" >
                    <Grid container item xs={12} lg={6} xl={4} justifyContent="center">
                        <Grid item mb={3} justifyContent="center">
                            <img className="blog_img" src={png1} alt="png1"/>
                        </Grid>
                        <Grid item xs={12} className="title_img" align="center">APARTMENTS DESIGNED BY THE LEADING INTERIOR EXPERTS</Grid>
                        <Grid item xs={12} className="desc_img" align="center">MAY 19, 2021 / NICOLE WILLIS</Grid>

                    </Grid>
                    <Grid container item xs={12} lg={6} xl={4}  justifyContent="center">
                        <Grid item mb={3}>
                            <img className="blog_img" src={png2} alt="png2"/>
                        </Grid>
                        <Grid item xs={12}  className="title_img" align="center">LEARN THE TRUTH ABOUT REAL ESTATE INDUSTRY</Grid>
                        <Grid item xs={12}  className="desc_img" align="center">JANUARY 10, 2021 / NICOLE WILLIS</Grid>

                    </Grid>
                    <Grid container item xs={12} lg={6} xl={4}  mt={{sm: 3, xl: 0}} justifyContent="center">
                        <Grid item mb={3} >
                            <img className="blog_img" src={png3} alt="png3"/>
                        </Grid>
                        <Grid item xs={12}  className="title_img" align="center">NATURE INSPIRED DESIGN IN THE HEART OF A METROPOLIS</Grid>
                        <Grid item xs={12}  className="desc_img" align="center">JANUARY 3, 2021 / NICOLE WILLIS</Grid>

                    </Grid>
                    <Grid container item xs={12} lg={6} xl={4}  justifyContent="center">
                        <Grid item mb={3} mt={4}>
                            <img className="blog_img" src={png4} alt="png4"/>
                        </Grid>
                        <Grid item xs={12}  className="title_img" align="center">ARCHITECTURE IS NOT BASED ON CONCRETE AND STEEL</Grid>
                        <Grid item xs={12}  className="desc_img" align="center">NOVEMBER 27, 2020 / NICOLE WILLIS</Grid>

                    </Grid>

                    <Grid container item xs={12} lg={6} xl={4}  justifyContent="center">
                        <Grid item mb={3} mt={4}>
                            <img className="blog_img" src={png5} alt="png5"/>
                        </Grid>
                        <Grid item xs={12}  className="title_img" align="center">ARCHITECTURE IS NOT BASED ON CONCRETE AND STEEL</Grid>
                        <Grid item xs={12}  className="desc_img" align="center">NOVEMBER 27, 2020 / NICOLE WILLIS</Grid>

                    </Grid>

                    <Grid container item xs={12} lg={6} xl={4}  justifyContent="center">
                        <Grid item mb={3} mt={4}>
                            <img className="blog_img" src={png6} alt="png6"/>
                        </Grid>
                        <Grid item xs={12}  className="title_img" align="center">ARCHITECTURE IS NOT BASED ON CONCRETE AND STEEL</Grid>
                        <Grid item xs={12}  className="desc_img" align="center">NOVEMBER 27, 2020 / NICOLE WILLIS</Grid>

                    </Grid>

                    <Grid container item xs={12} lg={6} xl={4}  justifyContent="center">
                        <Grid item mb={3} mt={4}>
                            <img className="blog_img" src={png7} alt="png7"/>
                        </Grid>
                        <Grid item xs={12}  className="title_img" align="center">ARCHITECTURE IS NOT BASED ON CONCRETE AND STEEL</Grid>
                        <Grid item xs={12}  className="desc_img" align="center">NOVEMBER 27, 2020 / NICOLE WILLIS</Grid>

                    </Grid>

                    <Grid container item xs={12} lg={6} xl={4} justifyContent="center">
                        <Grid item mb={3} mt={4}>
                            <img className="blog_img" src={png8} alt="png8"/>
                        </Grid>
                        <Grid item xs={12} className="title_img" align="center">ARCHITECTURE IS NOT BASED ON CONCRETE AND STEEL</Grid>
                        <Grid item xs={12}  className="desc_img" align="center">NOVEMBER 27, 2020 / NICOLE WILLIS</Grid>

                    </Grid>

                    <Grid container item xs={12} lg={6} xl={4}  justifyContent="center">
                        <Grid item mb={3} mt={4}>
                            <img className="blog_img" src={png9} alt="png9"/>
                        </Grid>
                        <Grid item xs={12}  className="title_img" align="center">ARCHITECTURE IS NOT BASED ON CONCRETE AND STEEL</Grid>
                        <Grid item xs={12}  className="desc_img" align="center">NOVEMBER 27, 2020 / NICOLE WILLIS</Grid>

                    </Grid>
                </Grid>

                <Footer/>
            </Grid>
        </Box>
    )
}