import { Box, CircularProgress, Grid } from '@mui/material'
import * as React from 'react';
import ButtonAppBar from './components/AppBar/AppBar';
import MenuBar from './components/BurgerMenu/BurgerMenu';
import ListAdminPanelProjects from './Projects/ListProject/ListAdminPanelProjects';
import ViewSingleProject from './Projects/ViewProject/ViewSingleProject';
import AddSingleProject from './Projects/AddProject/AddSingleProjectPage';
import UnconfirmedProject from './Unconfirmed/UnconfirmedProject';
import ViewProfilePage from './Profile/ViewProfile';
import Requests from './Requests/Requests';
import Pricing from './Pricing/Pricing';
import { api } from '../../api/api';
import { fillUserInfo } from '../../features/admin_panel/adminPanelSlice';
import { useDispatch } from 'react-redux';
import './css/admin_panel.css'


export default function MainPage({ purpose }) {
    const [loader, setLoader] = React.useState(true)
    const width = window.innerWidth
    const [sw_status, swSet] = React.useState(width <= 900 ? false : true)
    const [userInfo, setUserInfo] = React.useState(null)
    const dispatch = useDispatch()


    React.useEffect(() => {

        api(`/auth/user/`).then((res) => {
                setUserInfo(res.data)
                setLoader(false)
                dispatch(fillUserInfo({...res.data}))
        })
        
    }, [])

    return (
        loader ?
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh !important' }}>
                <CircularProgress />
            </Box>
            :
            <Box className="box_admin_panel">

                <React.Fragment>
                    <ButtonAppBar funSw={swSet} isOpen={sw_status} />
                    <Grid container className="container_admin_panel" p={0} alignItems="flex-start">
                        <Grid container item className={sw_status ? "container_menu_bar" : "container_menu_bar menu_bar_hide"}
                            xs={5.5} sm={3.5} md={sw_status ? 2.5 : 1} lg={sw_status ? 1.7 : .8} 
                            p={0} sx={{ 'height': '-webkit-fill-available' }}>
                            <MenuBar isOpen={sw_status} userInfo={userInfo}/>
                        </Grid>
                        <Grid container className='admin_panel_container' item mt={11} p={1} 
                            xs={12} sm={12} md={sw_status ? 9.5 : 11} lg={sw_status ? 10.3 : 11.2}
                            alignItems="flex-start">

                            {purpose === 'list_projects' && <ListAdminPanelProjects />}
                            {purpose === 'view_project' && <ViewSingleProject purpose={'view'}/>}
                            {purpose === 'add_project' && <AddSingleProject purpose={'add'}/>}
                            {purpose === 'edit_project' && <AddSingleProject purpose={'edit'}/>}
                            {purpose === 'unconfirmed_projects' && <UnconfirmedProject />}
                            {purpose === 'view_profile' && <ViewProfilePage />}
                            {purpose === 'requests' && <Requests />}
                            {purpose === 'price_list' && <Pricing />}

                        </Grid>
                    </Grid>
                </React.Fragment>
            </Box>
    )
}