import { Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, 
    Button, Snackbar, Alert} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormError from '../../AdminPanel/components/FormError/FormError'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';


export default function RecoveryFromEmail(){
    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onSubmit' })
    const [alertInfo, setAlertInfo] = useState({open: false, color: 'success', message: ''});
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const {uid, token} = useParams()
    const handleSave = (form_data) => {
        const data = {
            uid: uid,
            token: token,
            password: form_data.password
        }
        axios.post(`${process.env.REACT_APP_API_DEV}/auth/recovery_password_by_email`, 
            data
        ).then((res)=> {
            setAlertInfo({open: true, color: 'success', message: res.data.message})
            reset()
        }).catch((err) => {
            setAlertInfo({open: true, color: 'error', message: err.response.data.message})
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertInfo(false);
    };

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <Grid item xs={12} mb={1}>
                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        sx={{ 'background': 'white' }}
                        type={showPassword ? 'text' : 'password'}
                        {...register('password', {
                            required: 'Password is required field!',
                            pattern: {
                                value: /.*([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+).*/,
                                message: 'The password must contain upper and lower case Latin letters, numbers and special character!'
                            },
                            minLength: {
                                value: 8,
                                message: 'The password must consist of at least 8 characters!'
                            }
                        })}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
                <FormError error={errors?.password} />
            </Grid>

            <Grid item xs={12} mb={1}>
                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Repeat password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        sx={{ 'background': 'white' }}
                        type={showPassword ? 'text' : 'password'}
                        {...register('repeat_password', {
                            required: 'Repeat password is required field!',
                            validate: (value, formValues) => value ===formValues.password || 'Passwords mismatch!',
                            
                                
                        })}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
                <FormError error={errors?.repeat_password} />
            </Grid>

            <Grid item xs={12} mb={1} align='center'>
                <Button type='submit' className='recovery_btn'>Save New Password</Button>
            </Grid>
            <Snackbar
                open={alertInfo.open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity={alertInfo.color}>{alertInfo.message}</Alert>
            </Snackbar>
        </form>
    )
}