import { Grid, Typography, Box, Tab, Skeleton, Button } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import EuroSharpIcon from '@mui/icons-material/EuroSharp';
import TabPanelGeneral from "./components/TabPanelGeneral";
import TabPanelLocation from "./components/TabPanelLocation";
import TabPanelDetails from "./components/TabPanelDetails";
import DrawingsDialog from '../../../../components/dialogs/DrawingsDialog';
import CertificateDialog from '../../../../components/dialogs/CertificateDialog';
import React, { useState } from 'react';
import './styles/general_info.css'

export default function GeneralInfo({drawings, certificates, general, location, details={}, purpose, project, delete_draw, delete_cert}) {

    const [draw_open, setDrawOpen] = useState(false)
    const [cert_open, setCertOpen] = useState(false)
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const get_discount = () => {
        let price = Number(general?.price.replaceAll('.',''))
        let discount = Number(general?.discount.replaceAll('.',''))
        let discount_price = (price - (discount * price / 100)).toFixed(0)
        return `${discount_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    return (
        <Grid container item xs={12} spacing={2} sx={{ p: { xs: 0, md: 1.5 } }}>
            <Grid item xs={12} >
                <Typography className="name_project_text">
                    {general?.name}
                </Typography>
                <Typography className="name_project_short_desc">
                    {general?.name
                        ? (general?.name)
                        : <Skeleton variant="rounded" height={20} sx={{ bgcolor: '#7e7e7e' }} />
                    }
                    {general?.name && location?.country && location?.region
                        ? ` from ${location?.country}, ${location?.region}`
                        : ''
                    }
                </Typography>
                <Typography className="discount_price">
                    {general?.discount
                        ?
                        <React.Fragment>
                            {general?.currency === 'USD' && <span>$</span>}
                            {general?.currency === 'EUR' && <EuroSharpIcon />}
                            <span>{general?.price}</span>
                        </React.Fragment>
                        : ''
                    }

                </Typography>
                <Typography className="price_text">
                    {general?.price
                        ? <React.Fragment>
                            {general?.currency  === 'USD' && <span>$</span>}
                            {general?.currency  === 'EUR' && <EuroSharpIcon />}
                            {general?.discount ? get_discount() : general?.price}
                            {general?.price && <span> (Excluding all tax or fees)</span>}
                        </React.Fragment>
                        : !details?.flat_for_land_basis && <Skeleton variant="rounded" height={20} width={'50%'} sx={{ bgcolor: '#7e7e7e', mt: '15px' }} />
                    }

                </Typography>
                <Typography className="dues_fee">{general?.price ? '(Dues or Additional VAT)': ''}</Typography>

                {details?.flat_for_land_basis &&
                        (<Typography className="typography_flat_for_land" fontSize={'30px'}>(Flat for Land Basis)</Typography>)
                    }
            </Grid>

            <Grid container  xs={12} pl={2}>
                <Box width={'100%'}>
                    <TabContext value={value} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" >
                                <Tab label="General" value={1} />
                                <Tab label="Details" value={2} />
                                <Tab label="Location" value={3} />
                            </TabList>
                        </Box>
                        <TabPanel value={1} sx={{ padding: '5px 0 !important' }}> <TabPanelGeneral data={general}/> </TabPanel>
                        <TabPanel value={2} sx={{ padding: '5px 0 !important' }}> <TabPanelDetails data={details}/> </TabPanel>
                        <TabPanel value={3} sx={{ padding: '5px 0 !important' }}> <TabPanelLocation data={location}/> </TabPanel>
                    </TabContext>
                </Box>
            </Grid>

            <Grid item xs={12} mt={1} align='start'>
                <Button className="view_btn_overview" onClick={() => setCertOpen(true)}>View Certificates</Button>
                <Button className="view_btn_overview" onClick={() => setDrawOpen(true)}>View Drawings</Button>
            </Grid>

            <DrawingsDialog open={draw_open} setOpen={setDrawOpen} drawings={drawings} purpose={purpose} project={project} delete_draw={delete_draw}/>
            <CertificateDialog open={cert_open} setOpen={setCertOpen} certificates={certificates} purpose={purpose} project={project} delete_cert={delete_cert}/>

        </Grid>
    )
}