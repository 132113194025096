
import {
    Grid, Card, CardContent, IconButton, Typography, Table, TableBody,
    TableRow, TableCell, CardActions, Button, Snackbar, Alert
} from '@mui/material'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import CheckIcon from '@mui/icons-material/Check';
import { api } from '../../../../api/api';
import {useState} from 'react';


export default function RealtorPriceList({user}) {

    const [alertMessage, setAlertMessage] = useState({open: false, color: '', message: ''});

    async function updateTariff(){
        await api(`/auth/tariffs/${user.pk}/trial_tariff/`, 'PATCH').then((res) => {
            setAlertMessage({open: true, color: 'success', message: res.data.message})
        }).catch((err) => {
            setAlertMessage({open: true, color: 'secondary', message: err.response.data.message})
        })
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertMessage({...alertMessage, open: false});
    };

    return (
        <Grid container p={0}>
            <Grid container item xs={12} sm={6} lg={4} p={2}>
                <Card sx={{ width: '-webkit-fill-available' }}>
                    <CardContent>
                        <Grid container p={0} justifyContent='center'>

                            <Grid item xs={12} align='center' pt={4}>
                                <IconButton aria-label="delete" size="large" className='price_icon'>
                                    <WorkspacePremiumIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='h6' className='tariff_name_h6'>
                                    Trial <span className='span_h6'>45 Days</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='caption' className='tariff_description'>
                                    Create one end product for a client, transfer that end product to your client,
                                    charge them for your services. The license is then transferred to the client.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='h4'>
                                    <sup>$</sup>0<span className='tariff_price'>/Lifetime</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>

                                <Table size='small'>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon color='success' /></TableCell>
                                            <TableCell> Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon color='success' /></TableCell>
                                            <TableCell>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                            </Grid>


                        </Grid>
                    </CardContent>
                    <CardActions align='center' >
                        <Grid container p={0} justifyContent='center' mb={2}>
                            <Grid item xs={12}>
                                <Button variant='outlined' className='btn_order_now'
                                onClick={() => updateTariff()}
                                >Order Now</Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>

            

            <Snackbar
                open={alertMessage.open}
                autoHideDuration={6000}
                message="Note archived"
                onClose={handleClose}
            >
                <Alert severity={alertMessage.color} >{alertMessage.message}</Alert>
            </Snackbar>
        </Grid>

    )
}