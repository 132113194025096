import { Grid, Paper, Typography, Button } from '@mui/material';
import RealEstate from './ui/TypeEstate/RealEstate';
import LandTerrain from './ui/TypeEstate/LandTerrain';
import Tourism from './ui/TypeEstate/Tourism';
import Business from './ui/TypeEstate/Business';
import Facades from './ui/Features/Facades';
import InternalFeatures from './ui/Features/InternalFeatures';
import ExternalFeatures from './ui/Features/ExternalFeatures';
import Surroundings from './ui/Features/Surroundings';
import Transporting from './ui/Features/Transporting';
import LandscapeNaturalFeatures from './ui/Features/LandscapeNaturalFeatures';
import GeneralAreaFeatures from './ui/Features/GeneralAreaFeatures';
import InfrastructureFeatures from './ui/Features/InfrastructureFeatures';
import NaturalResources from './ui/Features/NaturalResources';
import HotelRules from './ui/Features/HotelRules';
import RoomFeatures from './ui/Features/RoomFeatures';
import BuildingFormat from './ui/Features/BuildingFormat';
import ExternalFeaturesStructure from './ui/Features/ExternalFeaturesStructure';
import { useFormContext } from 'react-hook-form';

export default function Details({ activeStep, setActiveStep, setCompleted, project }) {
    const { watch, trigger } = useFormContext()
    const type = watch('general.estate_category')

    const check_for_next = async () => {
        if (await trigger('details')) {
            setActiveStep(activeStep + 1)
            setCompleted({ 0: true, 1: true })
        }
    }

    const check_for_prev = () => {
        setActiveStep(activeStep - 1)
        setCompleted({ 0: true })
    }

    return (
        <Grid container p={2} component={Paper} className={activeStep !== 1 ? 'wrap_tab_container display_none' : 'wrap_tab_container'} alignContent='baseline' alignItems='flex-start'>
            <Grid item xs={12} pl={4} mb={2}>
                <Typography variant='h5' color='#fff'>Advert / Project Details</Typography>
            </Grid>

            <Grid container item xs={12} lg={6} p={2}>
                {type === 'real_estate' && <RealEstate project={project}/>}
                {type === 'land_and_terrain' && <LandTerrain project={project}/>}
                {type === 'tourism_and_facility' && <Tourism project={project}/>}
                {type === 'business' && <Business project={project}/>}
            </Grid>

            <Grid container item xs={12} lg={6} p={2} justifyContent='center'>

                {type === 'real_estate' && <Facades project={project}/>}
                {type === 'real_estate' && <InternalFeatures project={project}/>}
                {type === 'real_estate' && <ExternalFeatures project={project}/>}
                {(type === 'real_estate' || type === 'land_and_terrain' || type === 'tourism_and_facility' || type === 'business') && <Surroundings project={project}/>}
                {(type === 'real_estate' || type === 'business') && <Transporting project={project}/>}
                {(type === 'real_estate' || type === 'land_and_terrain' || type === 'tourism_and_facility' || type === 'business') && <LandscapeNaturalFeatures project={project}/>}

                {type === 'land_and_terrain' && <GeneralAreaFeatures project={project}/>}
                {(type === 'land_and_terrain' || type === 'tourism_and_facility' || type === 'business') && <InfrastructureFeatures project={project}/>}
                {(type === 'land_and_terrain' || type === 'tourism_and_facility') && <NaturalResources project={project}/>}

                {type === 'tourism_and_facility' && <HotelRules project={project}/>}
                {type === 'tourism_and_facility' && <RoomFeatures project={project}/>}

                {type === 'business' && <BuildingFormat project={project}/>}
                {type === 'business' && <ExternalFeaturesStructure project={project}/>}

            </Grid>

            {!type && 
                <Grid container justifyContent={'center'}>
                    <Typography color='gray' fontSize={'22px'}>You need to select the type of estate.</Typography>
                </Grid>
            }

            <Grid container item xs={12} justifyContent={'space-between'}>
                <Button onClick={check_for_prev} className='next_btn'>PREV</Button>
                <Button onClick={check_for_next} className='next_btn'>NEXT</Button>
            </Grid>

        </Grid>
    )
}