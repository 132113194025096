import { Grid, Card, CardContent, Typography, Link, Button} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';



export default function EmailConfirm() {

    return (
        <Grid sx={{height: '100vh'}} container p={0} justifyContent='center' alignItems='center'>
            <Grid item xs={6}>
                <Card>
                    <CardContent align='center'>
                        <CheckIcon sx={{ fontSize:"60px !important"}} color="success" />
                        <Typography variant="h4">
                            You have successfully verified your email address!
                        </Typography>

                        <Link href="/sign_in/" >
                            <Button variant='outlined' sx={{marginTop: '30px !important'}}>Go to sign in</Button>
                        </Link>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}