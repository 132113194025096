import React, { useRef } from 'react'
import { Dialog, Grid, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { deleteDrawing } from '../../../../../features/projects/projectsSlice.js';
import { useDispatch } from 'react-redux';
import Slider from "react-slick";
import './dialogs.css'

const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

export default function DrawingsDialog({ open, setOpen, drawings, purpose, project, delete_draw }) {
    const dispatch = useDispatch()

    const handleClose = () => {
        setOpen(false);
    };

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const delete_current = () => {
        let current_slide = sliderRef.innerSlider.state.currentSlide
        let url_slide = sliderRef.props.children[current_slide].props.children.props.src
        let project_index = project?.drawings.findIndex(item => item.drawing === url_slide)

        if (project_index !== -1) {
            dispatch(deleteDrawing({ 'draw_id': project?.drawings[project_index].id, 'project_id': project.id }))
            delete_draw(false, sliderRef.props.children[current_slide].props.children.props.position)
        }
        else {
            delete_draw(false, sliderRef.props.children[current_slide].props.children.props.position)
        }
    }

    const delete_all = () => {
        delete_draw(true)
        Object.keys(project?.drawings).forEach((key) => {
            dispatch(deleteDrawing({ 'draw_id': project?.drawings[key].id, 'project_id': project.id }))
        })
    }

    return (
        <React.Fragment>
            <Dialog
                className='draw_dialog'
                fullWidth={true}
                maxWidth={'lg'}
                open={open}
                onClose={handleClose}
            >
                <DialogContent>

                    <Grid container p={0} mt={2} mb={2} justifyContent='center'>
                        <Grid item xs={12} md={11}>
                            <Slider {...settings} ref={slider => {
                                sliderRef = slider;
                            }}>
                                {drawings?.map((img, index) => (
                                    <div>
                                        <img src={img[0]} position={img[1]} width={'100%'} alt={index} />
                                    </div>
                                ))}

                            </Slider>
                        </Grid>

                        {drawings.length === 0 &&
                            <Grid item xs={12} align='center' className='no_content_block' mt={4}>
                                <Typography>No Content</Typography>
                            </Grid>
                        }
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={purpose === 'edit' && drawings.length > 0 ? 'flex-end' : 'center'} mt={4}>
                        <Grid container item xs={12} md={4} p={0} justifyContent={'center'} spacing={1}>
                            <Grid item >
                                <Button onClick={previous} className='navigate_dialog_btn'>
                                    <ArrowBackRoundedIcon />
                                </Button>
                            </Grid>
                            <Grid item >
                                <Button onClick={next} className='navigate_dialog_btn'>
                                    <ArrowForwardRoundedIcon />
                                </Button>
                            </Grid>
                        </Grid>

                        {purpose === 'edit' && drawings.length > 0 &&
                            <Grid container item xs={12} md={4} p={0} justifyContent={'center'} spacing={2}>

                                <Grid item >
                                    <Button className='navigate_dialog_dark_btn' onClick={delete_current}>
                                        <CloseRoundedIcon />
                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button className='navigate_dialog_dark_btn' onClick={delete_all}>Clear All</Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}