import { Button, Checkbox, FormControlLabel, FormGroup, Grid, CircularProgress, MenuItem,
    Typography, TextField, Box, Select, FormControl, InputLabel, FormLabel
} from "@mui/material";
import { useEffect, useState } from 'react';
import { api } from '../../../../../api/api';
import './filter_projects.css'
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';



export default function FilterBlog() {


    return (
        <Grid container className='filter_container'>
            <Grid container item xs={12} p={0} mt={4}>
                <Grid container item xs={12} md={2} p={0}  mb={2}>
                    <Grid item xs={12} mb={2} mt={2}>
                        <Typography color={'white'}>Type of Estate</Typography>
                    </Grid>
                    <Grid item xs={12}>

                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" >
                                <FormControlLabel
                                    className='checkbox_field'
                                    value="top"
                                    control={<Checkbox 
                                        checkedIcon={<SquareRoundedIcon />}
                                    />}
                                    label="Real Estate"
                                    labelPlacement="right"
                                />
                                <FormControlLabel
                                    className='checkbox_field'
                                    value="start"
                                    control={<Checkbox 
                                        checkedIcon={<SquareRoundedIcon />}
                                    />}
                                    label="Land & Terrain"
                                    labelPlacement="right"
                                />
                                <FormControlLabel
                                    className='checkbox_field'
                                    value="bottom"
                                    control={<Checkbox 
                                        checkedIcon={<SquareRoundedIcon />}
                                    />}
                                    label="Tourism & Facility"
                                    labelPlacement="right"
                                />
                                <FormControlLabel
                                    className='checkbox_field'
                                    value="end"
                                    control={<Checkbox 
                                        checkedIcon={<SquareRoundedIcon />}
                                    />}
                                    label="Business"
                                    labelPlacement="right"
                                />
                            </FormGroup>
                        </FormControl>
                        
                    </Grid>
                </Grid>

                <Grid item alignSelf={'normal'} mb={1} mt={1} className='filter_line '></Grid>
            </Grid>


            <Grid container item xs={12}></Grid>
        </Grid>
    )
}