import { Grid } from "@mui/material";
import { useState, useEffect } from 'react'
import InvestorPriceList from "./price_lists/InvestorPriceList";
import RealtorPriceList from "./price_lists/RealtorPriceList";
import "./pricing.css";
import { api } from "../../../api/api";


export default function Pricing() {
    const [userInfo, setUserInfo] = useState(null)

    useEffect(() => {
        async function fetchUserData() {
            await api(`/auth/user/`).then((res) => {
                setUserInfo(res.data)
            })
        }
        fetchUserData()
    }, [])


    return (
        <Grid container p={0} alignItems="flex-start" justifyContent='space-between'>

            {userInfo && userInfo.have_access_like_realtor &&
                <RealtorPriceList user={userInfo} />
            }
            {userInfo && userInfo.have_access_like_investor &&
                <InvestorPriceList />
            }

        </Grid>
    )
}