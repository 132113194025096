import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    user: {}
}

const adminPanelSlice = createSlice({
    name: 'adminPanel',
    initialState,
    reducers: {
        fillUserInfo(state, action) {
            state.user = action.payload
        }
    }
})

export default adminPanelSlice.reducer

export const { fillUserInfo } = adminPanelSlice.actions
export const selectUserInfo = (state) => state.adminPanel.user
export const selectUserLogo = (state) => state.adminPanel.user.company.logo