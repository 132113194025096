import { Grid, Button, TextField, Typography, Snackbar, Alert } from '@mui/material'
import FormError from '../../AdminPanel/components/FormError/FormError'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import axios from 'axios'


export default function RecoveryPassword() {

    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onSubmit' })
    const [alertInfo, setAlertInfo] = useState({open: false, color: 'success', message: ''});

    const handleEmailIsCorrect = (form_data) => {
        axios.post(`${process.env.REACT_APP_API_DEV}/auth/check_email/`, {
            email: form_data.email
        }).then((res) => {
            setAlertInfo({open: true, color: 'success', message: res.data.message})
            reset()
        }).catch((err) => {
            setAlertInfo({open: true, color: 'error', message: err.response.data.message})
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertInfo(false);
    };

    return (
        <form onSubmit={handleSubmit(handleEmailIsCorrect)}>

            <Grid item xs={12} mb={1}>
                <Typography variant="body1" align='center' sx={{ 'fontSize': '25px', 'fontWeight': '500' }}>
                    Password Recovering
                </Typography>
            </Grid>
            <Grid item xs={12} mb={3}>
                <TextField
                    size="small"
                    sx={{ 'background': 'white' }}
                    fullWidth
                    {...register('email', {
                        required: 'Email is required field!',
                        pattern: {
                            message: 'Please enter valid email!',
                            value: /^((([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\.]{1,}[0-9А-Яа-я]{1}))@([-0-9A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/
                        }
                    })}
                    id="filled-required"
                    label="Email address"
                    variant="outlined"
                />
                <FormError error={errors?.email} />
            </Grid>

            <Grid item xs={12} mb={2} align='center'>
                <Button type="submit" variant='outlined' className='recovery_btn'>Send request for recovering</Button>
            </Grid>
                
            <Snackbar
                open={alertInfo.open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity={alertInfo.color}>{alertInfo.message}</Alert>
            </Snackbar>
        </form>
    )
}