import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Badge, Typography } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail';
import Label from '@mui/icons-material/Label';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useNavigate } from 'react-router-dom';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { api } from '../../../../../api/api';
import { useSelector } from 'react-redux';
import { countUnconfirmedProjects } from '../../../../../features/projects/projectsSlice';

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    background: '#202020',
    [`& .${treeItemClasses.content}`]: {
        color: '#fff',
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
            margin: '2px',
        },
        '&:hover': {
            backgroundColor: '#bd9a68d6',
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: '#bd9a68d6',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
    const theme = useTheme();
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;

    const styleProps = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color': '#161616',
        'margin': '2px'
    };

    return (
        <StyledTreeItemRoot
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={styleProps}
            {...other}
            ref={ref}
        />
    );
});

export default function TreeMenu({ userInfo, swStatus }) {
    const [countRequests, setCountRequests] = React.useState(0)
    const unconfirmed_projects = useSelector(countUnconfirmedProjects)
    const navigate = useNavigate()


    React.useEffect(() => {
        async function fetchRequests() {
            await api(`/api/requests/`, 'GET', {}, true).then((res) => {
                setCountRequests(res.data.filter(req => req.status === 'new').length)
            })
        }

        fetchRequests()
    }, [])
    return (
        <TreeView
            className={swStatus ? 'tree_show' : 'tree_hide'}
            aria-label="gmail"
            defaultExpanded={['1']}
            defaultSelected={'2'}
            defaultCollapseIcon={swStatus && <ArrowDropDownIcon />}
            defaultExpandIcon={swStatus && <ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            sx={{ height: 264, flexGrow: 1, maxWidth: swStatus ? 400 : 100, overflowY: 'auto' }}
        >
            <StyledTreeItem nodeId="1" labelText={swStatus && "Projects"} labelIcon={Label} onClick={() => navigate('/admin_panel/projects')}>
            {swStatus &&
                <StyledTreeItem
                    onClick={() => navigate('/admin_panel/projects')}
                    nodeId="2"
                    labelText={swStatus && "My projects"}
                    labelIcon={ForumIcon}
                    //   labelInfo="90"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    colorForDarkMode="#bd9a68d6"
                    bgColorForDarkMode="#071318"
                />}
                {userInfo?.is_staff && swStatus &&
                    <Badge badgeContent={unconfirmed_projects} className='badge' invisible={countRequests > 0 ? false : true}>
                        <StyledTreeItem
                            onClick={() => navigate('/admin_panel/unconfirmed_projects')}
                            nodeId="3"
                            labelText={swStatus && "Unconfirmed"}
                            labelIcon={InfoIcon}
                            //   labelInfo="2,294"
                            color="#e3742f"
                            bgColor="#fcefe3"
                            colorForDarkMode="#FFE2B7"
                            bgColorForDarkMode="#191207"
                        />
                    </Badge>
                }

            </StyledTreeItem>

            <Badge badgeContent={countRequests} className='badge' invisible={countRequests > 0 && swStatus ? false : true}>
                <StyledTreeItem
                    onClick={() => navigate('/admin_panel/requests')}
                    nodeId="4" labelText={swStatus && 'Requests'} labelIcon={MailIcon} />
            </Badge>


            {userInfo?.is_staff === false &&
                <StyledTreeItem
                    onClick={() => navigate('/admin_panel/pricing')}
                    nodeId="5" labelText={swStatus && "Pricing"} labelIcon={LocalOfferIcon} />
            }
        </TreeView>
    );
}