import {Grid, Paper} from '@mui/material';
import PublishAdvert from './ui/PublishAdvert';

export default function Publishing({activeStep, setActiveStep, setCompleted, success, purpose}) {
    return (
        <Grid container p={2} component={Paper} 
        className={activeStep !== 4 ? 'wrap_tab_container display_none' : 'wrap_tab_container'} alignItems='baseline'>
            <PublishAdvert activeStep={activeStep} setActiveStep={setActiveStep} setCompleted={setCompleted} success={success} purpose={purpose}/>
        </Grid>
    )
}