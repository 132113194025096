import { Grid, Button, Typography } from "@mui/material"
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import Slider from "react-slick";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { deleteAllMedia } from "../../../../../../features/projects/projectsSlice";


export default function SliderCards({ cardList, project, setSliderValue }) {
    const [nav1, setNav1] = useState(null);
    const [cards, setCards] = useState([])
    const [countCards, setCountCards] = useState(0)
    const {resetField} = useFormContext()
    const dispatch = useDispatch()
    let sliderRef = useRef(null);

    useEffect(() => {
        let count = 0
        Object.values(cardList).forEach(item => {
            if (item.url && item.url.trim() !== '') {
                count++;
            }
        });
        setCards(cardList)
        setCountCards(count)
        
    }, [cardList])


    const next = () => {
        sliderRef.slickNext();
      };
      const previous = () => {
        sliderRef.slickPrev();
      };

    const clear_all = () => {
        resetField('media')
        if (project) {
            dispatch(deleteAllMedia({'project_id': project.id}))
        }
    }

    return (
        <Grid container item xs={12} p={1} justifyContent='center' alignItems={'stretch'}>
            <Grid container item xs={2} p={1}>
                <Grid item xs={12} align={'center'}>
                    <Button className='navigation_sort_images_btn_type_1' onClick={previous}>
                        <ArrowBackRoundedIcon />
                    </Button>
                </Grid>

                <Grid item xs={12} align={'center'}>
                    <Button className='navigation_sort_images_btn_type_2'>Crop</Button>
                </Grid>
            </Grid>
            <Grid container item xs={8} p={0} justifyContent={'center'} >
                {countCards === 0 &&
                    <Grid item xs={12} height={400} className='no_content_block no_content_sort_image' alignContent={'center'} align='center'>
                        <Typography >No Content</Typography>
                    </Grid>
                }

                {countCards > 0 &&
                    <Slider arrows={false} asNavFor={nav1} ref={slider => {
                        sliderRef = slider
                        setSliderValue(slider)
                        }}>
                        {cards?.map((el, index) => (
                                el.url !== '' &&
                                    (el.position !== 10 
                                    ?
                                    <div key={index}>
                                        <img className='preview_image' height={430} key={index} src={el.url} alt='Preview' />
                                    </div>
                                    :
                                    <ReactPlayer height={430} controls={true} url={el.url} />
                                    )
                                    
                        ))}

                    </Slider>
                }

            </Grid>
            <Grid container item xs={2} p={1} >
                <Grid item xs={12} align={'center'}>
                    <Button className='navigation_sort_images_btn_type_1' onClick={next}>
                        <ArrowForwardRoundedIcon />
                    </Button>
                </Grid>

                <Grid item xs={12} align={'center'}>
                    <Button onClick={clear_all} className='navigation_sort_images_btn_type_2'>clear all</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}