import { Grid, Typography } from "@mui/material";
import ProjectCard from "../../../../components/ProjectCard/ProjectCard";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectProject } from "../../../../../../../features/projects/projectsSlice";
import { useEffect, useState } from "react";

export default function Preview() {
    const project = useSelector((state) => selectProject(state, 'edit'))
    const { watch, formState: { dirtyFields } } = useFormContext()
    const [image_preview, setImagePreview] = useState('')

    useEffect(() => {
        let pr_id = project?.media.length > 0 ? project?.media?.findIndex(item => item.position === 1) : -1
        if (project && pr_id !== -1) {
            setImagePreview(project?.media[pr_id].file)
        } else if (dirtyFields.media) {
            let first_item = watch('media')?.find(el => el.position === 1)
            
            if (first_item) {
                setImagePreview(URL.createObjectURL(first_item.file))
            } else {
                setImagePreview(URL.createObjectURL(watch('media')[0].file))
            }
        } else {
            if (project?.media.length > 0) {
                setImagePreview(project?.media[0].file)
            }
        }

    }, [watch('media'), project])


    return (
        <Grid container alignItems='center' item xs={12} spacing={2} sx={{ p: { xs: 0, md: 1.5 }, borderLeft: { xs: 'none', lg: '1px solid #BD9A68' }, minHeight: '-webkit-fill-available !important' }}>
            <Grid items xs={12} align='center'>
                <Typography variant="h6" color='white'>Advert Preview</Typography>
            </Grid>

            <Grid items xs={12} align='center' mt={2}>
                <ProjectCard content={watch('general')} location={watch('location')} image_preview={image_preview} purpose={'preview'} details={watch('details')} />
            </Grid>
        </Grid>
    )
}