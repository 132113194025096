import { Grid, Paper, Button } from "@mui/material";
import AddressLocation from "./ui/AddressLocation";
import MapLoad from "./ui/MapLoad";
import { useFormContext } from "react-hook-form";

export default function Location({ activeStep, setActiveStep, setCompleted, project, purpose }) {
    const { trigger } = useFormContext()

    const check_for_next = async () => {
        if (await trigger('location')) {
            setActiveStep(activeStep + 1)
            setCompleted({ 0: true, 1: true, 2: true })
        }
    }

    const check_for_prev = () => {
        setActiveStep(activeStep - 1)
        setCompleted({ 0: true, 1: true })
    }


    return (
        <Grid container p={2} component={Paper}
            className={activeStep !== 2 ? 'wrap_tab_container display_none' : 'wrap_tab_container'} alignItems='baseline'>
            <AddressLocation project={project} purpose={purpose}/>
            <MapLoad project={project}/>

            <Grid container item xs={12} justifyContent={'space-between'}>
                <Button onClick={check_for_prev} className='next_btn'>PREV</Button>
                <Button onClick={check_for_next} className='next_btn'>NEXT</Button>
            </Grid>
        </Grid>
    )
}