import {BrowserRouter, Routes, Route} from "react-router-dom";
import HomePage from './pages/HomePage/Home';
import RegistrationPage from "./pages/Registration/Registration";
import SuccessReg from './pages/Registration/SuccessRegistration/success_registration';

import OverView from "./pages/AboutPage/OverView/Overview";
import Mission from "./pages/AboutPage/Mission/Mission";
import OurTeam from "./pages/AboutPage/OurTeam/OurTeam";

import Blog from './pages/HomePage/Blog/Blog';
import Contact from './pages/ContactPage/Contact';
import Projects from './pages/ProjectsPage/Projects';
import ProjectInfo from './pages/ProjectsPage/ProjectDetail/ProjectInfo';

import EmailConfirm from "./pages/EmailConfirm/EmailConfirm";

import NotFound from "./pages/NotFound/NotFound";
import MainPage from "./pages/AdminPanel/MainPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function App() {
  return (
      <BrowserRouter>
            <Routes>
                <Route element={<HomePage />} path="/" />
                <Route element={<RegistrationPage purpose="sign_up"/>} path="/sign_up"/>
                <Route element={<RegistrationPage purpose="sign_in"/>} path="/sign_in"/>
                <Route element={<RegistrationPage purpose="recovery_password" />} path="recovery-password"/>
                <Route element={<RegistrationPage purpose="recovery_password_from_email" />} path="recovery-password/:uid/:token"/>

                <Route element={<SuccessReg />} path="/success_registration"/>

                <Route element={<OverView />} path="about/overview" />
                <Route element={<Mission />} path="about/mission" />
                <Route element={<OurTeam />} path="about/our_team" />


                <Route element={<Blog />} path="blog"/>
                <Route element={<Contact />} path="contact"/>

                <Route element={<Projects />} path="projects"/>
                <Route element={<ProjectInfo />} path="project_info/:id"/>

                <Route element={<MainPage purpose={'list_projects'} />} path="admin_panel/projects" />
                <Route element={<MainPage purpose={'view_project'} />} path="admin_panel/project_view/:id" />
                <Route element={<MainPage purpose={'add_project'} />} path="admin_panel/add_project" />
                <Route element={<MainPage purpose={'edit_project'} />} path="admin_panel/project_edit/:id" />

                <Route element={<MainPage purpose={'view_profile'} />} path="admin_panel/profile" />

                <Route element={<MainPage purpose={'requests'} />} path="admin_panel/requests" />
                <Route element={<MainPage purpose={'unconfirmed_projects'} />} path="admin_panel/unconfirmed_projects" />


                <Route element={<MainPage purpose={'price_list'} />} path="admin_panel/pricing"/>
                
                <Route element={<EmailConfirm />} path="email-confirmed"/>



                <Route element={<NotFound />} path="*"/>

            </Routes>
      </BrowserRouter>
  );
}
