import { Grid, Button, CircularProgress } from "@mui/material";
import {useFormContext} from 'react-hook-form';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import {useState, useEffect} from 'react'


export default function PublishAdvert({activeStep, setActiveStep, setCompleted, success, purpose}) {
    const { formState: { errors, dirtyFields } } = useFormContext()
    const [loading, setLoading] = useState(false)

    function handleCheckErrors() {
        if ('location' in errors) {
            setActiveStep(2);
            setCompleted({0: true, 1: true})
        }
        if ('details' in errors) {
            setActiveStep(1)
            setCompleted({0: true})
        }
        if ('general' in errors || 'media' in errors || 'drawings' in errors || 'certificates' in errors) {
            setActiveStep(0)
            setCompleted({0: true})
        }


        if (Object.keys(errors).length === 0){
            setLoading(true)
        }
    }

    const check_for_prev = () => {
        setActiveStep(activeStep - 1)
        setCompleted({ 0: true, 1: true, 2: true, 3: true })
    }

    useEffect(() => {
        setLoading(false)
    }, [success])


    return (
        <Grid container item xs={12} md={6} spacing={2} sx={{ p: { xs: 0, md: 1.5 } }}>
            <Button onClick={handleCheckErrors} disabled={Object.keys(dirtyFields).length === 0 ? true: false}
                type="submit" className={
                    Object.keys(dirtyFields).length === 0  ? 'view_btn_overview btn_disabled' :
                    loading ? 'view_btn_overview btn_disabled' : 'view_btn_overview' 
                    }>
                    {!loading 
                        ? purpose === 'add' 
                            ? 'Create Advert' 
                            : 'Update Advert' 
                        : <CircularProgress className="circular_loader_publishing" /> 
                    }
            </Button>
            {success && <CheckCircleOutlineRoundedIcon className='complete_add_svg' />}

            <Grid container item xs={12} justifyContent={'flex-start'}>
                <Button onClick={check_for_prev} className='next_btn'>PREV</Button>
            </Grid>
            
        </Grid>
    )
}