import { Grid, Typography, FormControl, TextField, Autocomplete } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import FormError from "../../../../../components/FormError/FormError";
import { api } from '../../../../../../../api/api';
import React, { useEffect, useState } from 'react'
import { is_english_text } from '../../../../../../../validators/file_validators';
import { transliterate as tr } from 'transliteration'


export default function AddressLocation({ project, purpose }) {
    const { register, formState: { errors }, control } = useFormContext()
    const [countries, setCountry] = useState([])
    const [regions, setRegions] = useState([])

    const [countryValue, setCountryValue] = useState({})
    const [regionValue, setRegionValue] = useState({})


    useEffect(() => {
        async function fetchCountry() {
            await api('/api/countries/').then((res) => { setCountry(res.data) })
        }
        fetchCountry()
    }, [])

    function loadRegions(countryCode) {
        setRegionValue({})
        api(`/api/regions/${countryCode}/`).then((res) => {
            setRegions(res.data.edges) 
        })
    }

    useEffect(() => {
        if (project && countries) {
            let country = Object.values(countries).find(item => tr(item.name) === project?.location?.country)
            if (country) {
                setCountryValue(country)
                loadRegions(country?.countryCode)
            }
        }
    }, [countries])

    useEffect(() => {
        if (regions && project && Object.keys(countryValue).length !== 0){
            let region = regions.find(item => tr(item.node?.name) === project?.location?.region)
            region && setRegionValue(region)
        }

    }, [regions])


    return (
        <Grid container item xs={12} md={5.5} spacing={2} sx={{ p: { xs: 0, md: 1.5 } }}>
            <Grid item xs={12}>
                <Typography variant='h5' color='#fff'>Address / Location</Typography>
            </Grid>

            <Grid item xs={12} md={6} >
                <FormControl sx={{ width: '99%' }} size="small" >
                    <Controller
                        name={'location.country'}
                        defaultValue={project?.location?.country}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                value={Object.keys(countryValue).length !== 0 ? countryValue : null}
                                size="small"
                                options={countries}
                                onChange={(e, v) => {
                                    v && loadRegions(v?.countryCode)
                                    v && setCountryValue(v)
                                    v && field.onChange(tr(v.name))
                                    }
                                }
                                required
                                getOptionLabel={(option) => tr(option.name)}
                                renderInput={(params) => <TextField className='tab_text_field' {...params} label={<React.Fragment>Country<span>*</span></React.Fragment>} />}
                            />
                        )}
                    />
                </FormControl>
                <FormError error={errors?.location?.country} errors={errors.location} name={'country'} />
            </Grid>
        

            <Grid item xs={12} md={6} >
                <FormControl sx={{ width: '99%' }} size="small" >

                    <Controller
                        name={'location.region'}
                        defaultValue={project?.location.region}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                value={Object.keys(regionValue).length !== 0 ? regionValue : null}
                                size="small"
                                options={regions}
                                onChange={(e, v) => {
                                    v && field.onChange(tr(v.node.name))
                                    v && setRegionValue(v)
                                    v && field.onChange(tr(v.node.name))
                                    }}
                                required
                                getOptionLabel={(option) => tr(option.node?.name)}
                                renderInput={(params) => <TextField className='tab_text_field' {...params} label={<React.Fragment>Region<span>*</span></React.Fragment>} />}
                            />
                        )}
                    />
                </FormControl>
                <FormError error={errors?.location?.region} errors={errors.location} name={'region'} />
            </Grid>
        

            <Grid item xs={12} md={4.5} >
                <TextField
                    className='tab_text_field'
                    defaultValue={project?.location.state}
                    {...register('location.state', {
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label="State"
                />
                <FormError error={errors?.location?.state} errors={errors.location} name={'state'} />
            </Grid>

            <Grid item xs={12} md={4.5} >
                <TextField
                    className='tab_text_field'
                    defaultValue={project?.location.city}
                    {...register('location.city', {
                        required: true,
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>City / Province<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.location?.city} errors={errors.location} name={'city'} />
            </Grid>

            <Grid item xs={12} md={3} >
                <TextField
                    className='tab_text_field'
                    defaultValue={project?.location.zip_code}
                    {...register('location.zip_code', {
                        required: true,
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Zip Code<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.location?.zip_code} errors={errors.location} name={'zip_code'} />
            </Grid>

            <Grid item xs={12} >
                <TextField
                    className='tab_text_field'
                    defaultValue={project?.location.full_address}
                    {...register('location.full_address', {
                        required: true,
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    size='small'
                    multiline
                    minRows={4}
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Full Address<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.location?.full_address} errors={errors.location} name={'full_address'} />
            </Grid>

        </Grid>
    )
}