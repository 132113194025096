import * as React from 'react'
import { Button, Dialog, Grid, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import './dialog_del.css'


export default function DialogDelete({ is_open, handleDelete, setDelDialog }) {

    const handleClose = () => {
        setDelDialog(false);
    };

    return (
        <React.Fragment>
            <Dialog
                className='dialog_del_container'
                open={is_open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent sx={{ padding: '10px' }}>
                    <DialogContentText className='dialog_content_text' align='center'>
                        Are you sure to delete all progress for adding project?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container xs={12} spacing={2} justifyContent={'space-evenly'}>
                        <Grid item xs={3}>
                            <Button className='btn_go4invest' onClick={handleDelete}> Yes </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button className='btn_go4invest' onClick={handleClose}> Decline </Button>
                        </Grid>
                    </Grid>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}