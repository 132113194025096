import { Grid, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button,
    Snackbar, Alert
} from "@mui/material"
import { useState, useEffect } from 'react'
import { api } from "../../../api/api"
import "./requests.css"

export default function Requests() {
    const [requestsData, requestsDataSet] = useState(null)
    const [open, setOpen] = useState(false);

    useEffect(() => {
        async function fetchData() {
            await api(`/api/requests/`, 'GET', {}, true).then((res) => {
                requestsDataSet(res.data)
            })
        }

        fetchData()
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    async function statusUpdate(pk) {
        await api(`/api/requests/${pk}/`, 'PATCH', { status: 'checked' }).then((res) => {
            requestsDataSet(res.data)
        })
    }

    return (
        <Grid container p={0} alignItems="flex-start" justifyContent='space-between'>
            <TableContainer component={Paper} className='requests_table'>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Sender</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Phone Number</TableCell>
                            <TableCell align="center">Type of estate</TableCell>
                            <TableCell align="center">Budget</TableCell>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Created Date</TableCell>
                            <TableCell align="center">Actions</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requestsData && requestsData.map((row) => (
                            <TableRow
                                key={row.pk}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{row.sender}</TableCell>
                                <TableCell component="th" scope="row">{row.email}</TableCell>
                                <TableCell component="th" align="center">{row.phone_number}</TableCell>
                                <TableCell component="th" align="center">{row.type_of_estate}</TableCell>
                                <TableCell component="th" align="center">{row.budget_min}-{row.budget_max} {row.currency}</TableCell>
                                <TableCell component="th" align="center">{row.description}</TableCell>
                                <TableCell component="th" align="center">
                                    <Button variant="outlined" className="btn_status_request">{row.status}</Button>
                                </TableCell>
                                <TableCell component="th" align="center">{row.create_date}</TableCell>
                                <TableCell component="th" align="center">
                                    {row.status === 'new' ?
                                        <Button onClick={() => statusUpdate(row.pk)} className="btn_check" variant="contained" color="success">
                                            Set Checked
                                        </Button>
                                        :
                                        <Button className="btn_check colored" color="success">
                                            Checked
                                        </Button>
                                    }

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity="success">Company information was updated!</Alert>
            </Snackbar>

        </Grid>

    )
}