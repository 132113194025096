import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import './form_error.css'
import WarningIcon from '@mui/icons-material/Warning';


export default function FormError({ error, errors, name, align_to_right=false }) {
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (error) {
            const keys = Object.keys(errors);
            const index = keys.indexOf(name);
            index === 0 && setShow(true)
        }
    }, [error])

    return (
        <React.Fragment>
            {error && show &&
                <Card className={`card_error ${align_to_right && 'to_right'}`}>
                    <WarningIcon sx={{ color: '#bd9a68' }} />
                    <p>{error?.message === '' ? "Please fill in this field!" : error?.message}</p>
                </Card>
            }

        </React.Fragment>
    )
}