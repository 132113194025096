import { Grid, Typography } from '@mui/material';

const TypeEstate = {
    // Real Estate
    'house': 'House', 'villa': 'Villa', 'residence': 'Residence', 'mansion': 'Mansion', 'apartment': 'Apartment', 
    'condo': 'Condo', 'prefabricate': 'Prefabricate', 'penthouse_and_loft': 'Penthouse & Loft', 'ready_project': 'Ready Project',
    // Land & Terrain
    'plot_for_sale': 'Plot for Sale', 'farm_and_garden': 'Farm and Garden',
    // Tourism & Facility
    'hotel': 'Hotel', 'holiday_village_and_facility': 'Holiday Village & Facility',
    'thermal_hotel_and_facility': 'Thermal Hotel & Facility', 'boutique_places': 'Boutique Places',
    'camping_zone': 'Camping Zone', 'theme_parks_and_entertainment': 'Theme Parks & Entertainment',
    'authentic_and_historic_places': 'Authentic & Historic Places', 'natural_life_facility_and_park': 'Natural Life Facility & Park',
    // Business
    'plaza_and_business_center': 'Plaza & Business Center', 'shopping_mall': 'Shopping Mall',
    'office_and_bureau': 'Office & Bureau', 'shop_and_store': 'Shop & Store',
    'factory_and_warehouse': 'Factory & Warehouse', 'entertainment_and_amusement': 'Entertainment & Amusement',
    'workshop_and_production_place': 'Workshop & Production Place', 'cafe_bar_and_restaurant': 'Cafe Bar & Restaurant',
    'power_plants': 'Power Plants', 'hospital_and_health_center': 'Hospital & Health Center',
    'gas_station': 'Gas Station', 'other_places': 'Other Places'
}


const Categories = {'business' : 'Business', 'real_estate': 'Real Estate', 'land_and_terrain': 'Land & Terrain', 'tourism_and_facility': 'Tourism & Facility'}
const ProjectState = {'complete_project': 'Complete Project', 'under_construction': 'Under Construction', 'tenanted_estate': 'Tenanted Estate', 'off_plan_property': 'Off Plan Property'}

export default function TabPanelGeneral({data}) {

    const DATA = [
        ['estate_category', 'Estate Category'], ['estate_type', 'Estate Type'],
        ['gross_size', 'Total Size'], ['project_state', 'Project State']
    ]

    function get_data(field) {
        if (field === 'estate_category') {
            return Categories[data?.estate_category] 
        }
        if (field === 'estate_type') {
            return TypeEstate[data?.estate_type]
        }
        if (field === 'gross_size') {
            return `${data?.gross_size} ${data?.measure}`
        }
        if (field === 'project_state') {
            return ProjectState[data?.state]
        }
    }

    return (
        <Grid container xs={12} p={0}>
        
            {DATA.map((row) => (
                <Grid container xs={12} p={0} mt={1}  alignItems='flex-start'>
                    <Grid item xs={6}>
                        <Typography color='grey'>{row[1]}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color='white'>
                            {get_data(row[0])}
                        </Typography>
                    </Grid>
                </Grid>
            ))}

        </Grid>
    )
}