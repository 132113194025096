import { Grid, TextField, MenuItem, InputLabel, FormControl, Select} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import FormError from "../../../../../../components/FormError/FormError";
import { is_english_text } from "../../../../../../../../validators/file_validators";
import React from 'react';


export default function LandTerrain({ project }) {
    const { register, formState: { errors }, control } = useFormContext()

    return (
        <Grid container item xs={12} spacing={2} p={0}>
            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    fullWidth
                    defaultValue={project?.details?.land_condition}
                    {...register('details.land_condition', { required: true,
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    id="outlined-required"
                    label={<React.Fragment>Land Condition<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.land_condition} errors={errors.details} name={'land_condition'} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">
                        <React.Fragment>
                            Infrastructure
                        </React.Fragment>
                    </InputLabel>
                    <Controller
                        name={'details.infrastructure'}
                        defaultValue={project?.details?.infrastructure}
                        control={control}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Garden or Private Area"
                            >
                                <MenuItem value={'fully_equipped'}>Fully Equipped</MenuItem>
                                <MenuItem value={'under_construction'}>Under Construction</MenuItem>
                                <MenuItem value={'unmade'}>Unmade</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    defaultValue={project?.details?.environmental_condition}
                    {...register('details.environmental_condition', {
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    fullWidth
                    id="outlined-required"
                    label="Environmental Condition"
                />
                <FormError error={errors?.details?.environmental_condition} errors={errors.details} name={'environmental_condition'} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    defaultValue={project?.details?.zone_number}
                    {...register('details.zone_number', { required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                            } })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Zone Number<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.zone_number} errors={errors.details} name={'zone_number'} />
            </Grid>

            <Grid item xs={12} sm={6} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    defaultValue={project?.details?.parcel_number}
                    {...register('details.parcel_number', { required: true, min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                            } })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Parcel Number<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.parcel_number} errors={errors.details} name={'parcel_number'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    type="number"
                    defaultValue={project?.details?.sheet_number}
                    {...register('details.sheet_number', { min: {
                            value: 1,
                            message: 'The value must be greater than 0!'
                            } })}
                    size='small'
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Sheet Number</React.Fragment>}
                />
                <FormError error={errors?.details?.sheet_number} errors={errors.details} name={'sheet_number'} />
            </Grid>

            <Grid item xs={12} md={8} className='advert_field'>
                <TextField
                    className='tab_text_field'
                    size='small'
                    defaultValue={project?.details?.floor_area_ration}
                    {...register('details.floor_area_ration', { required: true,
                        validate: {
                            is_english: v => is_english_text(v) || 'You have to use only English'
                        }
                    })}
                    fullWidth
                    id="outlined-required"
                    label={<React.Fragment>Floor Area Ratio / Floor Space Index (Precedent)<span>*</span></React.Fragment>}
                />
                <FormError error={errors?.details?.floor_area_ration} errors={errors.details} name={'floor_area_ration'} />
            </Grid>

            <Grid item xs={12} md={4} className='advert_field'>
                <FormControl sx={{ width: '99%' }} size="small">
                    <InputLabel id="demo-simple-select-label">
                        <React.Fragment>
                            Swap Statement <span>*</span>
                        </React.Fragment>
                    </InputLabel>
                    <Controller
                        name={'details.swap_statement'}
                        defaultValue={project?.details?.swap_statement}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <Select {...field}
                                className='tab_text_field'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Garden or Private Area"
                            >
                                <MenuItem value={'yes'}>yes</MenuItem>
                                <MenuItem value={'no'}>no</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <FormError error={errors?.details?.swap_statement} errors={errors.details} name={'swap_statement'} />
            </Grid>

        </Grid>
    )
}