import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import "./overview.css";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import pic1 from "../../../assets/images/about/overview/pic_1.png";
import pic3 from "../../../assets/images/about/overview/pic_3.png";
import pic4 from "../../../assets/images/about/overview/pic_4.png";
import pic5 from "../../../assets/images/about/overview/pic_5.png";
import pic6 from "../../../assets/images/about/overview/pic_6.png";
import pic7 from "../../../assets/images/about/overview/pic_7.png";
import pic8 from "../../../assets/images/about/overview/pic_8.png";
import pic9 from "../../../assets/images/about/overview/pic_9.png";
import pic10 from "../../../assets/images/about/overview/pic_10.png";
import QuickInquiry from "../../HomePage/components/QuickInquiry/quick_inquiry";
import ToolBar from "../../HomePage/components/ToolBar/ToolBar";
import Footer from "../../HomePage/components/Footer/Footer";
import vid from '../../../assets/video/about/overview/video.mp4'



export default function OverView() {
    const widthWin = window.innerWidth


    return (
        <Box>
            <ToolBar mode="dark" page='about' />

            <Grid container pl={0} pr={0}>
                <Grid container mt={20}>
                    <Grid item xl={12} xs={12}>
                        <Typography variant="h1" className="overview_title">
                            OVERVIEW
                        </Typography>
                    </Grid>
                    <Grid item xl={12} xs={12} align="center" alignContent="center">
                        <Typography variant="body1">
                            <a href="/" className="color_home_overview">HOME</a> <NavigateNextIcon />
                            <span className="overview_text-overview">OVERVIEW</span>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container mt={4} mb={8} pl={0} pr={0} alignItems="center">
                    <Grid container item xs={12} lg={6} justifyContent="flex-end" className="div_header_overview">
                        <img width="462" height="640" src={pic1} alt="pic_1" />
                    </Grid>
                    <Grid container item xs={12} lg={6} mt={16} className="div_header_overview">
                        <Grid item xl={12} sm={12} mb={2} className="overview_who_we_are">
                            <Typography variant="caption" id="title">
                                WHO WE ARE
                            </Typography>
                        </Grid>

                        <Grid item xl={8} sm={8} align="start">
                            <Typography variant="body1" className="we_are_30_years overview_who_we_are" align="left">
                                30 YEARS OF LEADERSHIP IN REAL ESTATE SECTOR
                            </Typography>
                        </Grid>

                        <Grid item xs={10} lg={6}>
                            <Typography variant="caption">
                            At Go4Invest, we understand the profound impact that strategic investments and smart real
                            estate decisions can have on your financial future. Whether you're a seasoned investor
                            looking to diversify your portfolio or a first-time homebuyer exploring the world of real estate,
                            we're here to guide you through every step of your journey.
                            </Typography>
                        </Grid>

                        <Grid item mb={14} mt={6} xs={12} className="btn_overview_text_center">
                            <a className="btn btn_tran" data-animation="fadeInUp" data-delay=".6s">
                                <div className="btn_m">
                                    <div className="btn_c">
                                        <div className="btn_t1">LEARN MORE</div>
                                        <div className="btn_t2">LEARN MORE</div>
                                    </div>
                                </div>
                            </a>
                        </Grid>

                    </Grid>

                    <Grid container xs={12} lg={12} mt={16} p={0} сlassName="parallax-img">
                        
                            <Grid container className="parallax-img">
                                <div className="jarallax-container">

                                {widthWin > 900 ?
                                    <video className="videoPlayer" autoPlay loop muted preload="auto">
                                        <source src={vid} type="video/mp4" />
                                    </video>
                                    :
                                    <img className="videoPlayer" 
                                    src="https://static.tildacdn.com/tild6136-6338-4364-b130-623336383430/Waterline_Square_cre.jpg" alt="" />
                                }
                                </div>
                            </Grid>
                        
                    </Grid>


                    <Grid container mt={10} justifyContent="center">
                        <Grid item xl={12} sm={12} align="center">
                            <Typography variant="caption" className="leadship_overview">
                                LEADERSHIP
                            </Typography>
                        </Grid>

                        <Grid item xl={6} sm={6} mt={2} mb={6} align="center">
                            <Typography variant="h2" className="meet_with_overview">
                                MEET WITH OUR AMAZING TEAM MEMBERS
                            </Typography>
                        </Grid>

                        <Grid container item xl={10} p={0} xs={12} justifyContent="space-evenly">

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} mb={2} align="center">
                                <img src={pic3} alt="pic_3" />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} mb={2} align="center">
                                <img src={pic4} alt="pic_4" />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} mb={2} align="center">
                                <img src={pic5} alt="pic_5" />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} mb={2} align="center">
                                <img src={pic6} alt="pic_6" />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} mb={2} align="center">
                                <img src={pic7} alt="pic_7" />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} mb={2} align="center">
                                <img src={pic8} alt="pic_8" />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} mb={2} align="center">
                                <img src={pic9} alt="pic_9" />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} mb={2} align="center">
                                <img src={pic10} alt="pic_10" />
                            </Grid>

                            <Grid item xl={12} sm={12} align="center" mt={4}>
                                <a href="/about/our_team" className="btn btn_tran" data-animation="fadeInUp"
                                    data-delay=".6s">
                                    <div className="btn_m">
                                        <div className="btn_c">
                                            <div className="btn_t1">VIEW ALL MEMBERS</div>
                                            <div className="btn_t2">VIEW ALL MEMBERS</div>
                                        </div>
                                    </div>
                                </a>
                            </Grid>
                        </Grid>

                        <QuickInquiry />
                    </Grid>
                </Grid>


            </Grid>

            <Footer />

        </Box>
    )
}