import {Card, CardHeader, CardContent, Grid, CardActions, Button,
    FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Snackbar, Alert
} from "@mui/material"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormError from '../../components/FormError/FormError'
import { useForm } from "react-hook-form"
import * as React from 'react'
import { api } from "../../../../api/api";


export default function ChangePassword() {
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm({ mode: 'onChange' })
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const handleSave = async (form_data) => {
        await api(`/auth/change-password/`, 'PUT', {
            current_password: form_data.current_password,
            new_password: form_data.new_password
        }).then((res) => {
            setOpenSuccess(true)
            reset()
        }).catch((err) => {
            setOpenError(true)
        })
    }


    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccess(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenError(false);
    };

    return (
        <React.Fragment>
            <Card className="card_profile">
                <CardHeader
                    title="Change Password"
                />
                <form onSubmit={handleSubmit(handleSave)}>
                    <CardContent className="card_content_change_password">

                        <Grid container xs={12} md={6} p={1} justifyContent='center'>
                            <Grid item xs={12} p={1}>
                                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined" className='change_password_input'>
                                    <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        size="small"
                                        type={showPassword ? 'text' : 'password'}
                                        {...register('current_password', {
                                            required: 'Confirm Password is required field!',
                                        })}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Current Password"
                                    />
                                </FormControl>
                            </Grid>



                            <Grid item xs={12} p={1}>
                                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined" className='change_password_input'>
                                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        size="small"
                                        type={showPassword ? 'text' : 'password'}
                                        {...register('new_password', {
                                            required: 'Password is required field!',
                                            pattern: {
                                                value: /.*([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+).*/,
                                                message: 'The password must contain upper and lower case Latin letters, numbers and special character!'
                                            },
                                            minLength: {
                                                value: 4,
                                                message: 'The password must consist of at least 8 characters!'
                                            }
                                        })}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="New Password"
                                    />
                                </FormControl>
                                <FormError error={errors?.new_password} />
                            </Grid>

                            <Grid item xs={12} p={1} >
                                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined" className='change_password_input'>
                                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        size="small"
                                        type={showPassword ? 'text' : 'password'}
                                        {...register('confirm_password', {
                                            required: 'Confirm Password is required field!',
                                            validate: value => value === getValues('new_password') || "The passwords do not match"

                                        })}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password"
                                    />
                                </FormControl>
                                <FormError error={errors?.confirm_password} />
                            </Grid>
                        </Grid>




                    </CardContent>

                    <CardActions>
                        <Grid container p={1} justifyContent="flex-end">
                            <Grid item mr={2}>
                                <Button type="submit" variant="contained" className="change_password_btn">Change Password</Button>
                            </Grid>

                            <Grid item>
                                <Button onClick={() => reset()} variant="outlined" color="error">Clear</Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </form>
            </Card>

            <Snackbar
                open={openSuccess}
                autoHideDuration={6000}
                onClose={handleCloseSuccess}
                message="Note archived"
            >
                <Alert severity="success">Your password has been successfully updated!</Alert>
            </Snackbar>

            <Snackbar
                open={openError}
                autoHideDuration={6000}
                onClose={handleCloseError}
                message="Note archived"
            >
                <Alert severity="error">Current password is wrong!</Alert>
            </Snackbar>
        </React.Fragment>
    )
}