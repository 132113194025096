import * as React from 'react';
import {Typography, Grid, Avatar} from '@mui/material';
import "./testimon.css";
import png1 from "../../../assets/images/home/testmon/1.png";
import png2 from "../../../assets/images/home/testmon/2.png";
import png3 from "../../../assets/images/home/testmon/3.png";



export default function Testimon() {
    return (
        <Grid containter justifyContent="center"  className="testi_container">
            <Grid mt={12}  container item justifyContent="center" pr={0} pl={0} alignItems="center" >
                <Grid item xs={12}>
                    <Typography variant="body1" className="title_testi">
                        SUPPORT CENTER
                    </Typography>
                </Grid>

                <Grid item xs={10} lg={6} justifyContent="center">
                    <Typography variant="body2" className="desc_testi">
                        We are assist for you with our expert team that all our investors trust
                    </Typography>
                </Grid>
            </Grid>

            <Grid container item justifyContent="center" pr={0} pl={0} mt={6}>
                <Grid container lg={8} xs={12} p={0} item  justifyContent="flex-start" alignItems="center" spacing={3}>
                    <Grid item>
                        <Avatar className='testi_avatar' sx={{ width: 80, height: 80}} alt="First Customer" src={png1}/>
                    </Grid>
                    <Grid item >
                        <svg xmlns="http://www.w3.org/2000/svg" width="535" height="220" viewBox="0 0 535 220"
                             className="svg_testimonial" >
                            <text x="40" y="50" className="text_svg">
                                "Working with Go4Invest was a game-changer for my
                            </text>
                            <text x="40" y="70" className="text_svg">
                                investment strategy! Their knowledgeable team provided
                            </text>
                            <text x="40" y="90" className="text_svg">
                                personalized advice that significantly boosted my portfolio.
                            </text>

                            <text x="40" y="110" className="text_svg">
                                Grateful for the valuable insights and professional service."
                            </text>

                            <text x="40" y="160" fill="#BD9A68" className="text2_svg">
                                JEFFREY KRESHEK
                            </text>
                            <text x="40" y="180" className="text2_svg" fill="#BD9A68">NEW YORK</text>
                            <mask id="path-1-inside-1_1211_412" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M27.3142 0C18.4777 0 11.3142 7.16344 11.3142 16V98.6865L0.000500679 110L11.3142 121.314V204C11.3142 212.837 18.4777 220 27.3142 220H518.314C527.151 220 534.314 212.837 534.314 204V16C534.314 7.16344 527.151 0 518.314 0H27.3142Z"/>

                            </mask>
                            <path
                                d="M11.3142 98.6865L12.2334 99.6058L12.6142 99.225V98.6865H11.3142ZM0.000500679 110L-0.918738 109.081L-1.83798 110L-0.918738 110.919L0.000500679 110ZM11.3142 121.314H12.6142V120.775L12.2334 120.395L11.3142 121.314ZM12.6142 16C12.6142 7.88141 19.1956 1.3 27.3142 1.3V-1.3C17.7597 -1.3 10.0142 6.44547 10.0142 16H12.6142ZM12.6142 98.6865V16H10.0142V98.6865H12.6142ZM0.919739 110.919L12.2334 99.6058L10.395 97.7673L-0.918738 109.081L0.919739 110.919ZM12.2334 120.395L0.91974 109.081L-0.918738 110.919L10.395 122.233L12.2334 120.395ZM12.6142 204V121.314H10.0142V204H12.6142ZM27.3142 218.7C19.1956 218.7 12.6142 212.119 12.6142 204H10.0142C10.0142 213.555 17.7597 221.3 27.3142 221.3V218.7ZM518.314 218.7H27.3142V221.3H518.314V218.7ZM533.014 204C533.014 212.119 526.433 218.7 518.314 218.7V221.3C527.869 221.3 535.614 213.555 535.614 204H533.014ZM533.014 16V204H535.614V16H533.014ZM518.314 1.3C526.433 1.3 533.014 7.88141 533.014 16H535.614C535.614 6.44547 527.869 -1.3 518.314 -1.3V1.3ZM27.3142 1.3H518.314V-1.3H27.3142V1.3Z"
                                fill="#202020" mask="url(#path-1-inside-1_1211_412)"/>

                        </svg>
                    </Grid>
                </Grid>

                <Grid container lg={8} xs={12} p={0} item  justifyContent="flex-end" alignItems="center" spacing={3} mt={1}>
                    <Grid item sx={{padding: '0 0 0 15px !important'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="535" height="220" viewBox="0 0 535 220"
                             fill="true" className="svg_testimonial">

                            <text x="40" y="50" className="text_svg">
                                "Thank for their expert guidance in navigating the real estate
                            </text>
                            <text x="40" y="70" className="text_svg">
                                market to Go4Invest. They helped me find a property that
                            </text>
                            <text x="40" y="90" className="text_svg">
                                perfectly aligned with my goals and budget. Highly
                            </text>

                            <text x="40" y="110" className="text_svg">
                                recommended for anyone serious about real estate success!"
                            </text>

                            <text x="40" y="160" fill="#BD9A68" className="text2_svg">
                                MIKE STEVENSON
                            </text>
                            <text x="40" y="180" className="text2_svg" fill="#BD9A68">FLORIDA</text>
                            <mask id="path-1-inside-1_1211_423" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M16 0C7.16344 0 0 7.16344 0 16V204C0 212.837 7.16345 220 16 220H507C515.837 220 523 212.837 523 204V121.314L534.314 110L523 98.6865V16C523 7.16344 515.837 0 507 0H16Z"/>
                            </mask>
                            <path
                                d="M523 121.314L522.081 120.395L521.7 120.775V121.314H523ZM534.314 110L535.233 110.919L536.152 110L535.233 109.081L534.314 110ZM523 98.6865H521.7V99.225L522.081 99.6058L523 98.6865ZM1.3 16C1.3 7.88141 7.88141 1.3 16 1.3V-1.3C6.44547 -1.3 -1.3 6.44547 -1.3 16H1.3ZM1.3 204V16H-1.3V204H1.3ZM16 218.7C7.88142 218.7 1.3 212.119 1.3 204H-1.3C-1.3 213.555 6.44548 221.3 16 221.3V218.7ZM507 218.7H16V221.3H507V218.7ZM521.7 204C521.7 212.119 515.119 218.7 507 218.7V221.3C516.555 221.3 524.3 213.555 524.3 204H521.7ZM521.7 121.314V204H524.3V121.314H521.7ZM533.394 109.081L522.081 120.395L523.919 122.233L535.233 110.919L533.394 109.081ZM522.081 99.6058L533.394 110.919L535.233 109.081L523.919 97.7673L522.081 99.6058ZM521.7 16V98.6865H524.3V16H521.7ZM507 1.3C515.119 1.3 521.7 7.88141 521.7 16H524.3C524.3 6.44547 516.555 -1.3 507 -1.3V1.3ZM16 1.3H507V-1.3H16V1.3Z"
                                fill="#202020" mask="url(#path-1-inside-1_1211_423)"/>
                        </svg>
                    </Grid>
                    <Grid item sx={{paddingRight: '10px'}}>
                        <Avatar className='testi_avatar' sx={{ width: 80, height: 80}} alt="Second Customer" src={png2}/>
                    </Grid>
                </Grid>

                <Grid container lg={8} xs={12} p={0} item  justifyContent="flex-start" alignItems="center" spacing={3} mt={1}>
                    <Grid item>
                        <Avatar className='testi_avatar' sx={{ width: 80, height: 80}} alt="Third Customer" src={png3}/>
                    </Grid>
                    <Grid item sx={{padding: '0 0 0 15px !important'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="535" height="220" viewBox="0 0 535 220"
                             fill="true" className="svg_testimonial">
                            <text x="40" y="50" className="text_svg">
                                "Go4Invest stands out for its commitment to transparency.
                            </text>
                            <text x="40" y="70" className="text_svg">
                                They took the time to explain every investment option,
                            </text>
                            <text x="40" y="90" className="text_svg">
                                ensuring I felt confident in my decisions. Trustworthy,
                            </text>

                            <text x="40" y="110" className="text_svg">
                                professional, and results-driven - I'm a satisfied investor!"
                            </text>

                            <text x="40" y="160" fill="#BD9A68" className="text2_svg">
                                MERRIN DARDENNE
                            </text>
                            <text x="40" y="180" className="text2_svg" fill="#BD9A68">CHARLOTTE</text>

                            <mask id="path-1-inside-1_1211_412" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M27.3142 0C18.4777 0 11.3142 7.16344 11.3142 16V98.6865L0.000500679 110L11.3142 121.314V204C11.3142 212.837 18.4777 220 27.3142 220H518.314C527.151 220 534.314 212.837 534.314 204V16C534.314 7.16344 527.151 0 518.314 0H27.3142Z"/>
                            </mask>
                            <path
                                d="M11.3142 98.6865L12.2334 99.6058L12.6142 99.225V98.6865H11.3142ZM0.000500679 110L-0.918738 109.081L-1.83798 110L-0.918738 110.919L0.000500679 110ZM11.3142 121.314H12.6142V120.775L12.2334 120.395L11.3142 121.314ZM12.6142 16C12.6142 7.88141 19.1956 1.3 27.3142 1.3V-1.3C17.7597 -1.3 10.0142 6.44547 10.0142 16H12.6142ZM12.6142 98.6865V16H10.0142V98.6865H12.6142ZM0.919739 110.919L12.2334 99.6058L10.395 97.7673L-0.918738 109.081L0.919739 110.919ZM12.2334 120.395L0.91974 109.081L-0.918738 110.919L10.395 122.233L12.2334 120.395ZM12.6142 204V121.314H10.0142V204H12.6142ZM27.3142 218.7C19.1956 218.7 12.6142 212.119 12.6142 204H10.0142C10.0142 213.555 17.7597 221.3 27.3142 221.3V218.7ZM518.314 218.7H27.3142V221.3H518.314V218.7ZM533.014 204C533.014 212.119 526.433 218.7 518.314 218.7V221.3C527.869 221.3 535.614 213.555 535.614 204H533.014ZM533.014 16V204H535.614V16H533.014ZM518.314 1.3C526.433 1.3 533.014 7.88141 533.014 16H535.614C535.614 6.44547 527.869 -1.3 518.314 -1.3V1.3ZM27.3142 1.3H518.314V-1.3H27.3142V1.3Z"
                                fill="#202020" mask="url(#path-1-inside-1_1211_412)"/>
                        </svg>
                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )
}