import { Grid, Accordion, AccordionSummary, CardActions, AccordionDetails, Typography, Button,
  Card, CardContent
} from '@mui/material'
import Slider from "react-slick";
import { api } from '../../../api/api'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react'
import "./unconfirmed_projects.css"
import { useDispatch } from 'react-redux';
import { confirmProject } from '../../../features/projects/projectsSlice';


export default function UnconfirmedProject() {
  const dispatch = useDispatch()
  const [projects, setProjects] = React.useState(null)

  React.useEffect(() => {
    async function fetchData() {
      await api(`/api/projects/unconfirmed_projects/`).then((res) => {
        setProjects(res.data)
      })
    }
    fetchData()
  }, [dispatch])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const handleConfirm = (project_id) => {
    dispatch(confirmProject(project_id))
    const updatedData = projects.filter(project => project.pk !== project_id)
    setProjects(updatedData)

  }

  return (

    <Grid container p={0} alignItems="flex-start" justifyContent='space-between' className={projects?.length === 0 && 'no_product'}>
      
      {projects?.length > 0 &&

        projects.map((project) => (
          <Grid item xs={12} p={1} >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{project.name}</Typography>
                <Button className="btn_type_estate">{project.type_estate}</Button>
              </AccordionSummary>
              <AccordionDetails>
                <Card>
                  <CardContent >
                    <Grid container p={0} alignItems='start'>
                      <Grid item xs={12} md={6} xl={4} mb={2} sx={{ display: 'flex' }} justifyContent='center'>
                        <Slider {...settings}>
                          {project.project_images.map((image) => {
                            return (
                              <Grid item align='center'>
                                <img src={image.image} height='350px' alt="" />
                              </Grid>
                            )
                          })}
                          {project.drawings.map((draw) => {
                            return (
                              <Grid item align='center'>
                                <img src={draw.drawing} height='350px' alt="" />
                              </Grid>
                            )
                          })}

                        </Slider>
                      </Grid>

                      <Grid item xs={12} md={6} xl={4}>
                        <Typography variant='body1'>
                          {project.description}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} xl={4}>
                        {project.certificates.map((certificate) => (
                          <a className='link_certificate' href={certificate.certificate} target='blank'>{certificate.certificate}</a>
                        ))}
                      </Grid>
                    </Grid>
                  </CardContent>

                  <CardActions>
                    <Grid container xs={12} p={1} justifyContent='end'>
                      <Grid item >
                        <Button variant='outlined' color='success'
                          onClick={() => handleConfirm(project.pk)}
                        >Confirm</Button>
                      </Grid>
                    </Grid>
                  </CardActions>

                </Card>
              </AccordionDetails>
            </Accordion>

          </Grid>
        ))

      }
    </Grid>


  )
}