import * as React from 'react';
import {Grid, Typography, Alert} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './success_reg.css';


export default function SuccessReg(props) {

    return (
        <Grid container justifyContent="center" alignItems="center" className="success_reg_container">
            <Grid container item xs={4} sm={6} justifyContent="center" className="success_reg_wrap_container">

                <Grid container item >
                    <Grid container item xl={12} sm={12} justifyContent="center">
                        <CheckCircleOutlineIcon className="success_reg_svg"/>
                    </Grid>

                    <Grid container item xl={12} sm={12} justifyContent="center">
                        <Typography vaiant="body1" align="center" className="success_reg_h1"> REGISTRATION SUCCESS</Typography>
                    </Grid>
                </Grid>

                <Grid item xl={12} sm={12} mt={3}>
                    <Alert severity="success">Thank you! We have sent you email to {props.email}. Please
                        click the link in that message to activate your account.
                    </Alert>
                </Grid>

                <Grid item mt={3}>
                    <a href="/sign_in" className="btn btn_tran" data-animation="fadeInUp" data-delay=".6s">
                        <div className="btn_m">
                            <div className="btn_c">
                                <div className="btn_t1">Go To</div>
                                <div className="btn_t2">Sign In</div>
                            </div>
                        </div>
                    </a>
                </Grid>
            </Grid>
        </Grid>
    )
}