import * as React from 'react';
import {Grid} from '@mui/material';
import QuickInquiry from "../components/QuickInquiry/quick_inquiry";

import "./latest_news.css"
import png1 from "../../../assets/images/home/latest_news/1.png"
import png2 from "../../../assets/images/home/latest_news/2.png"
import png3 from "../../../assets/images/home/latest_news/3.png"
import png4 from "../../../assets/images/home/latest_news/4.png"

export default function News(){


    return (
        <Grid container justifyContent="center"  alignItems="flex-start" pt={15} mt={15} className="container_news">
            <Grid container item xs={12} lg={4} mb={6} justifyContent="center" >
                <Grid item align="center" xs={12} className="from_our_blog">FROM OUR BLOG</Grid>
                <Grid item align="center" xs={12}  className="latest_news_events" mt={1}>LATEST NEWS </Grid>
                <Grid item align="center" xs={12}  className="latest_news_events">& EVENTS</Grid>
                <Grid item align="center" xs={12}  mt={3}>
                    <a className="btn btn_tran" data-animation="fadeInUp" data-delay=".6s" href='/blog'>
                        <div className="btn_m">
                            <div className="btn_c">
                                <div className="btn_t1">EXPLORE ALL</div>
                                <div className="btn_t2">EXPLORE ALL</div>
                            </div>
                        </div>
                    </a>
                </Grid>
            </Grid>

            <Grid container item xs={12} lg={8} p={0} alignItems="flex-start" justifyContent="center">
                <Grid container item xs={12} md={6}  justifyContent="center">
                    <Grid item mb={3} justifyContent="flex-end" mt={4}>
                        <img className='news_image' src={png1} alt="png1"/>
                    </Grid>
                    <Grid item className="title_img" align='center'>APARTMENTS DESIGNED BY THE LEADING INTERIOR EXPERTS</Grid>
                    <Grid item className="desc_img">MAY 19, 2021 / NICOLE WILLIS</Grid>

                </Grid>
                <Grid container item xs={12} md={6} justifyContent="center">
                    <Grid item mb={3} mt={4}>
                        <img className='news_image' src={png2} alt="png2"/>
                    </Grid>
                    <Grid item className="title_img" align='center'>LEARN THE TRUTH ABOUT REAL ESTATE INDUSTRY</Grid>
                    <Grid item className="desc_img">JANUARY 10, 2021 / NICOLE WILLIS</Grid>

                </Grid>
                <Grid container item xs={12} md={6} justifyContent="center">
                    <Grid item mb={3} mt={4}>
                        <img className='news_image' src={png3} alt="png3"/>
                    </Grid>
                    <Grid item className="title_img" align='center'>NATURE INSPIRED DESIGN IN THE HEART OF A METROPOLIS</Grid>
                    <Grid item className="desc_img">JANUARY 3, 2021 / NICOLE WILLIS</Grid>

                </Grid>
                <Grid container item xs={12} md={6} justifyContent="center">
                    <Grid item mb={3} mt={4}>
                        <img className='news_image' src={png4} alt="png4"/>
                    </Grid>
                    <Grid item className="title_img" align='center'>ARCHITECTURE IS NOT BASED ON CONCRETE AND STEEL</Grid>
                    <Grid item className="desc_img">NOVEMBER 27, 2020 / NICOLE WILLIS</Grid>

                </Grid>
            </Grid>

            {/* Form send a question. */}
            <QuickInquiry></QuickInquiry>
                    </Grid>
    )
}