import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import React, { useEffect, useState } from 'react'
import axios from 'axios';

export default function MapLoad() {
    const { watch, setValue, setError, clearErrors } = useFormContext()
    const [load, setLoad] = useState(true)
    const [position, setPosition] = useState({lat: 53.54, lng: 10 })
    const address = watch('location.full_address')

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            if (address && address !== '') {
                try {
                    axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                        params: {
                            address: address,
                            key: process.env.REACT_APP_GOOGLE_KEY
                        }
                    }).then((res) => {
                        setLoad(true)
                        if (res?.data?.results[0]?.geometry?.location) {
                            clearErrors('location.full_address')
                            setPosition(res?.data?.results[0]?.geometry?.location)
                            setValue('location.lat', res?.data?.results[0]?.geometry?.location.lat)
                            setValue('location.lng', res?.data?.results[0]?.geometry?.location.lng)
                        }
                        else {
                            setError('location.full_address', {type: 'custom', message: 'Location not found!'})
                        }

                    })
                }
                catch (error) {
                }
            }
        }, 1000)

        return () => clearTimeout(delayTimer);

    }, [address])

    return (
        <Grid container item xs={12} md={6} spacing={2} sx={{ p: { xs: 0, md: 1.5 } }}>
            <Grid item xs={12}>
                <Typography variant='h5' color='#fff'>View On the Map</Typography>
            </Grid>

            <Grid container item xs={12}>

                {load &&
                    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_KEY}>
                        <div style={{ height: '50vh', width: '100%' }}>
                            <Map center={position} defaultZoom={12} >
                                <Marker position={position} />
                            </Map>
                        </div>
                    </APIProvider>
                }

            </Grid>

        </Grid>
    )
}