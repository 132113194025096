import React, { useState, useEffect} from 'react';
import { Grid, Dialog, DialogContent, DialogActions } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectProject } from '../../../../../../features/projects/projectsSlice';
import DragCards from './DragCards';
import SliderCards from './SliderCards';

let initial_card_data = [
    {id: 1, position: 1, url: '', real_url:''}, {id: 2, position: 2, url: '', real_url: ''}, {id: 3, position: 3, url: '', real_url: ''},
    {id: 4, position: 4, url: '', real_url: ''}, {id: 5, position: 5, url: '', real_url: ''}, {id: 6, position: 6, url: '', real_url: ''},
    {id: 7, position: 7, url: '', real_url: ''}, {id: 8, position: 8, url: '', real_url: ''}, {id: 9, position: 9, url: '', real_url: ''},
    {id: 10, position: 10, url: '', real_url: ''}
]

export default function SortImagesDialog({ open, setOpen }) {
    const project = useSelector((state) => selectProject(state, 'edit'))
    const { formState: { dirtyFields }, watch } = useFormContext()
    const [cardList, setCardList] = useState([])
    const [sliderValue, setSliderValue] = useState()

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        let data = structuredClone(initial_card_data)
        if (project) {
            Object.keys(project?.media).forEach((key) => {
                let card_ind = data.findIndex(el => el.position === project?.media[key].position)
                if (card_ind !== -1) {
                    data[card_ind].url = project?.media[key].file
                } 
            })
        }

        if (watch('media') && dirtyFields.media) {
            Object.keys(watch('media')).forEach((key) => {
                if (watch('media')[key].type.slice(0, 5) === 'image') {
                    let card_ind = data.find(el => el.position === watch('media')[key].position)
                    card_ind.url = URL.createObjectURL(watch('media')[key]?.file)
                    card_ind.real_url = watch('media')[key]?.file.name
                }
                if (watch('media')[key].type.slice(0, 5) === 'video') {
                    let card_ind = data.find(el => el.position === 10)
                    card_ind.url = URL.createObjectURL(watch('media')[key]?.file)
                    card_ind.real_url = watch('media')[key]?.file.name
                }
            })
        }
        
        setCardList(data)
    }, [watch('media'), project?.media ])


    return (
        <React.Fragment>
            <Dialog
                className='draw_dialog'
                fullWidth={true}
                maxWidth={'lg'}
                open={open}
                onClose={handleClose}
            >
                <DialogContent>

                    <Grid container p={0} mt={2} mb={2} className="slider-container drawing_slider" >
                        
                        <SliderCards cardList={cardList} project={project} setSliderValue={setSliderValue}/>
                        <DragCards cardList={cardList} setCardList={setCardList} sliderValue={sliderValue}/>

                    </Grid>
                
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}