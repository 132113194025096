import { Grid, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox } from "@mui/material";
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useFormContext } from "react-hook-form";
import React from 'react';

const FEATURES = [
    ['smart_house', 'Smart House'],
    ['alarm_system', 'Alarm System'],
    ['white_goods', 'White Goods'],
    ['encastered', 'Encastered'],
    ['fiber_optic_internet', 'Fiber Optic Internet'],
    ['intercom_system', 'Intercom System'],
    ['spot_lighting', 'Spot Lighting'],
    ['fireplace', 'Fireplace'],
    ['jacuzzi', 'Jacuzzi'],
    ['dressing_room', 'Dressing Room'],
    ['heated_floor', 'Heated Floor'],
    ['lux_bathroom', 'Lux Bathroom'],
    ['face_id', 'Face ID'],
    ['pantry', 'Pantry'],
    ['insulated', 'Insulated'],
    ['modern_built_floor', 'Modern Built Floor']
]

export default function InternalFeatures({project}) {
    const { control } = useFormContext()

    return (
        <Grid container item xs={12} mt={1} p={0} alignSelf='center'>
            <Accordion sx={{ width: '100%' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    Internal Features
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container p={0} mb={2}>
                        {FEATURES.map((feat) => (
                            <Grid item xs={3} align='center'>
                                <FormControlLabel
                                    size='small'
                                    className='tab_text_field checkbox_field'
                                    control={
                                        <React.Fragment >
                                            <Controller
                                                name={`features.internal_feat.${feat[0]}`}
                                                defaultValue={project?.details?.internal_feat?.[feat[0]]}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <Checkbox
                                                        icon={<CropSquareRoundedIcon />}
                                                        checkedIcon={<SquareRoundedIcon />}
                                                        checked={value}
                                                        onChange={(e) => onChange(e.target.checked)}
                                                    />
                                                )}
                                            />
                                        </React.Fragment>}
                                    label={feat[1]}
                                    labelPlacement="top"
                                />
                            </Grid>
                        ))}

                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}