import { Grid, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState, useRef } from 'react';
import { selectProject, fetchSingleProject } from '../../../../features/projects/projectsSlice';
import { useDispatch, useSelector } from "react-redux";
import GeneralInfo from '../AddProject/tabs/Overview/ui/GeneralInfo';
import OverviewSlider from '../AddProject/tabs/Overview/ui/Slider'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { FeaturesInfo } from '../AddProject/tabs/Overview/ui/FeaturesInfo';
import MenuProjectOptions from './components/MenuProjectOptions';
import DialogDelete from './dialog_windows/DialogDelete';
import { useParams } from 'react-router-dom';
import { api } from '../../../../api/api';
import './view_project.css'

export default function ViewProject({purpose}) {
    const [project, set_project] = useState(useSelector((state) => selectProject(state, 'view')))
    const position = { lat: Number(project?.location?.lat), lng: Number(project?.location?.lng) }
    const [accordion, setAccordion] = useState({ 1: true, 2: false, 3: false })
    const [progress_status, set_progress_status] = useState(false)
    const [delete_dialog, set_delete_dialog] = useState(false)

    const dispatch = useDispatch()
    const { id } = useParams()

    const slider_ref = useRef(null)

    function setValue(pos) {
        if (accordion[pos] === false) {
            setAccordion({ 1: false, 2: false, 3: false, [pos]: true })
        }
    }

    const handleDelete = () => {
        api(`/api/projects/${project.id}/`, 'DELETE').then(res => {
            window.location.href = '/admin_panel/projects'
        })
    }

    useEffect(() => {
        if (!project) {
            dispatch(fetchSingleProject(id)).then((res) => {
                set_project(res.payload)
            })
        }
    }, [])

    return (
        <Grid container className='view_page_container' alignContent={'baseline'} justifyContent={'center'} p={2}>
            {project &&
                <Grid container alignItems={'flex-start'}>
                    <Grid item xs={12} align={'end'}>
                        <MenuProjectOptions project_id={project?.id} project_name={project?.name} setProgress={set_progress_status} set_delete_dialog={set_delete_dialog}/>
                    </Grid>
                    <Grid item xs={12} md={4.5} pt={5}>
                        <GeneralInfo
                            drawings={Object.values(project?.drawings).map((item, index) => [item.drawing, index])}
                            certificates={Object.values(project?.certificates).map((item, index) => [item.certificate, index])}
                            general={project}
                            details={project.details}
                            location={project?.location}
                        />
                    </Grid>

                    <Grid container item xs={12} md={7.5} mt={2} p={1} alignSelf={'stretch'}>
                        <Grid container item className={accordion[1] === true ? 'el_accordion accordion_open' : 'el_accordion'}
                            onClick={() => setValue(1)} ref={slider_ref} justifyContent={'center'}>
                            <Grid item className='wrap_slider_on_view_page' >
                                <OverviewSlider imagePreviews={Object.values(project?.media).map((item) => {
                                    return {
                                        'file': item.file,
                                        'type': item.type.slice(0, 5),
                                        'position': item.position
                                    }

                                })} purpose={purpose} />
                            </Grid>
                        </Grid>
                        <Grid container item className={accordion[2] === true ? 'el_accordion accordion_open' : 'el_accordion'}
                            onClick={() => setValue(2)}>

                            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_KEY}>
                                <Map center={position} defaultZoom={12} >
                                    <Marker position={position} />
                                </Map>
                            </APIProvider>

                        </Grid>

                    </Grid>

                    <Grid item xs={12} p={0}>
                        <FeaturesInfo features={project.details} />
                    </Grid>
                </Grid>
            }
            {progress_status &&
                <Grid justifyContent={'center'} alignContent={'center'} container sx={{position:'absolute', top: 'auto', height: '100%', width: '-webkit-fill-available'}}>
                    <CircularProgress sx={{color: '#BD9A68'}} />
                </Grid>
            }

            <DialogDelete is_open={delete_dialog} setDelDialog={set_delete_dialog} handleDelete={handleDelete}/>

        </Grid>
    )
}