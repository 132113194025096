import { Grid, Box, Tab, Typography } from "@mui/material"
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState, useEffect } from "react";
import '../css/admin_panel.css'
import './profile_css.css'
import UserForm from "./forms/UserForm";
import CompanyForm from "./forms/CompanyForm";
import ChangePassword from "./forms/ChangePassword";
import ProfileTab from "./forms/ProfileTab";
import { api } from "../../../api/api"

export default function ViewProfilePage() {
    const [value, setValue] = useState('profile');
    const [userInfo, setUserInfo] = useState(null)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        async function fetchUserData() {
            await api(`/auth/user/`).then((res) => {
                setUserInfo(res.data)
            })
        }
        fetchUserData()
    }, [])

    function checkUserinfo() {
        if (userInfo.first_name === '' || userInfo.last_name === '' || userInfo.phone_number === '' ||
            userInfo.company.name === '' || userInfo.company.address === '') {
            return true
        }
        return false
    }

    return (

        <Grid container sx={{ padding: { xs: 1, md: 2 } }} className="view_profile_container" alignItems="flex-start" alignContent="baseline">
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Profile" value="profile" />
                            <Tab label="Edit profile" value="edit_profile" />
                            <Tab label="Change Password" value="change_password" />
                        </TabList>
                    </Box>

                    <TabPanel value="profile" sx={{ padding: '30px 0', }}>
                        <ProfileTab />
                    </TabPanel>

                    <TabPanel value="edit_profile" sx={{ padding: '30px 0', }}>
                        <Grid container p={0}>
                    
                            {(userInfo && !userInfo.email_confirm) &&
                                <Grid container p={0} className="alert_container" justifyContent="center" alignItems="center">
                                    <Grid item xs={1} align="center" p={1}>
                                        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4" width='24' fill="#ffe57f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ReportProblemOutlinedIcon"><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path></svg>
                                    </Grid>

                                    <Grid container item p={0} pt={2} pb={2} xs={11}>
                                        <Grid item xs={12}>
                                            <Typography sx={{ color: '#66635a' }}>Alert!</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography sx={{ fontWeight: 100, color: '#66635a' }}>
                                                You need to confirm your Email address!
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {userInfo && checkUserinfo() &&
                                <Grid container p={0} className="alert_container" justifyContent="center" alignItems="center">
                                    <Grid item xs={1} align="center" p={1}>
                                        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit css-1cw4hi4" width='24' fill="#ffe57f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ReportProblemOutlinedIcon"><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path></svg>
                                    </Grid>

                                    <Grid container item p={0} pt={2} pb={2} xs={11}>
                                        <Grid item xs={12}>
                                            <Typography sx={{ color: '#66635a' }}>Alert!</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography sx={{ fontWeight: 100, color: '#66635a' }}>
                                                You need to fill your personal!
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {userInfo &&
                                <Grid container p={0} mt={3} alignItems="stretch">
                                    <Grid container item xs={12} lg={6} p={1}>
                                        <UserForm user_info={userInfo} />
                                    </Grid>

                                    <Grid container item xs={12} lg={6} p={1}>
                                        <CompanyForm user_info={userInfo} />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </TabPanel>
                    <TabPanel value="change_password" sx={{ padding: '30px 0', }}>
                        <Grid container xs={12} p={1} mt={3}>
                            <ChangePassword />
                        </Grid>
                    </TabPanel>

                </TabContext>
            </Box>


        </Grid>

    )
}