import { useForm } from "react-hook-form"
import { Card, CardContent, CardHeader, Grid, TextField, CardActions, Button, Snackbar, Alert } from "@mui/material"
import { api } from "../../../../api/api"
import * as React from 'react'
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormError from '../../components/FormError/FormError'
import {media_type_validator, logo_size_validator} from '../../../../validators/file_validators'


export default function CompanyForm({ user_info }) {
    const [open, setOpen] = React.useState(false);
    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: user_info.company.name,
            address: user_info.company.address,
            website: user_info.company.website
        }
    })
    const handleSave = async (form_data) => {
        
        await api(`/auth/user-company/${user_info.pk}/`, 'PATCH', form_data, false, 
                { headers: {'Content-Type': 'multipart/form-data'}}
                ).then((res) => {
            setOpen(true)
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <React.Fragment>
            <Card className="card_profile" sx={{ height: '-webkit-fill-available' }}>
                <CardHeader
                    title="Company Information"
                />
                <form onSubmit={handleSubmit(handleSave)}>
                    <CardContent >
                        <Grid container p={0}>
                            <Grid item xs={12} mb={2}>
                                <TextField sx={{ width: '100%' }} size='small' className="text_field_profile"
                                    label="Company Name"
                                    placeholder="Company Name"
                                    {...register("name")}
                                />
                            </Grid>

                            <Grid item xs={12} mb={2}>
                                <TextField sx={{ width: '100%' }} size='small' className="text_field_profile"
                                    label="Address"
                                    placeholder="Address"
                                    {...register("address")}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField sx={{ width: '100%' }} size='small' className="text_field_profile"
                                    label="Website"
                                    placeholder="Website"
                                    {...register("website")}
                                />
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Button component="label" className='btn_profile_upload'
                                 variant="contained" startIcon={<CloudUploadIcon />}>
                                    Upload company Logo
                                    <VisuallyHiddenInput type="file"
                                        {...register('logo', {
                                            validate: {
                                                typeFile: v => media_type_validator(v) || 'You can upload JPG or PNG files!',
                                                sizeFile: v => logo_size_validator(v) || 'The file size must not exceed 0.5MB!'
                                            }
                                        } )}
                                    />
                                </Button>
                                <FormError error={errors?.logo} />
                            </Grid>
                            
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justifyContent='flex-end'>
                            <Grid item >
                                <Button className='btn_profile_save' type="submit" variant="contained" >Save</Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </form>
            </Card>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity="success">Company information was updated!</Alert>
            </Snackbar>
        </React.Fragment>
    )
}