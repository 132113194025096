import { Grid, Typography, Button } from '@mui/material';

const FEATURES = {
    'building_format_feat': 'Building Format Features', 'external_feat': 'External Features',
    'external_feat_structure': 'External Feat Structure', 'facades_feat': 'Facades', 'general_area_feat': 'General Area',
    'hotel_rules_feat': 'Hotel Rules', 'infrastructure_feat': 'Infrastructure Features', 'internal_feat': 'Internal Feature',
    'landscape_feat': 'Landscape', 'natural_resources_feat': 'Natural Resources', 'room_feat': 'Room Features',
    'surroundings_feat': 'Surroundings', 'transporting_feat': 'Transporting'
}

const OPTIONS = {
    // Building Format
    'for_factory': 'for Factory', 'for_office': 'for Office Job', 'for_trade': 'for Trade', 'station_or_warehouse': 'Station / Warehouse',
    // External Features
    'sports_facilities': 'Sports Facilities', 'public_pool': 'Public Pool', 'private_pool': 'Private Pool',
    'turkish_bath': 'Turkish Bath', 'sauna': 'Sauna', 'gym': 'Gym', 'kids_park': 'Kids Park', 'generator': 'Generator',
    // External Features Structures
    'power_plant': 'Power Plant', 'solar_energy': 'Solar Energy', 'agriculture_zone': 'Agriculture Zone',
    // Facades
    'west': 'West', 'east': 'East', 'north': 'North', 'south': 'South',
    // General Area Features
    'with_project': 'With Project', 'parcelling': 'Parcelling', 'corner_zone': 'Corner Zone', 'public_domain': 'Public Domain',
    // Hotel Rules
    'pet_friendly': 'Pet Friendly', 'smoke_free_rooms': 'Smoke Free Rooms', 'adults_only': 'Adults Only', 'thermal_features': 'Thermal Features',
    // Infrastructure Features
    'natural_gas': 'Natural Gas', 'water_line': 'Water Line', 'power_line': 'Power Line', 'highway': 'Highway / Road',
    'fiber_optic_cable': 'Fiber Optic Cable', 'internet': 'Internet', 'telecommunication': 'Telecommunication', 'sewer_system': 'Sewer System',
    // Internal Features
    'smart_house': 'Smart House', 'alarm_system': 'Alarm System', 'white_goods': 'White Goods', 'encastered': 'Encastered',
    'fiber_optic_internet': 'Fiber Optic Internet', 'intercom_system': 'Intercom System', 'spot_lighting': 'Spot Lighting',
    'fireplace': 'Fireplace', 'jacuzzi': 'Jacuzzi', 'dressing_room': 'Dressing Room', 'heated_floor': 'Heated Floor',
    'lux_bathroom': 'Lux Bathroom', 'face_id': 'Face ID', 'pantry': 'Pantry', 'insulated': 'Insulated',
    'modern_built_floor': 'Modern Built Floor',
    // Landscape
    'sea_or_lake': 'Sea / Lake', 'city_view': 'City View', 'mountain': 'Mountain', 'forest': 'Forest',
    // Natural Resources
    'spring_water': 'Spring Water', 'historical_place': 'Historical Place', 'ocean_or_sea': 'Ocean / Sea', 'seasonal_activities': 'Seasonal Activities',
    // Room Features
    'standard': 'Standard', 'suit': 'Suit', 'deluxe': 'Deluxe', 'apart': 'Apart',
    // Surroundings
    'downtown': 'Downtown', 'grocery': 'Grocery', 'shopping_mall': 'Shopping Mall', 'pharmacy': 'Pharmacy', 'hospital': 'Hospital',
    'police_station': 'Police Station', 'school': 'School', 'university': 'University', 'beach': 'Beach', 'sanctuary': 'Sanctuary',
    'fire_department': 'Fire Department', 'city_hall': 'City Hall',
    // Transporting
    'subway': 'Subway / Metro', 'bus_station': 'Bus Station', 'railway': 'Railway / Train', 'airport': 'Airport'
}


function setFeaturesData(data) {
    let new_data = {}
    for (let key in data) {
        if (data[key] !== null && Object.values(data[key]).includes(true) ) {
            new_data[key] = data[key]
        }
    }

    return new_data
}

export function FeaturesInfo({ features }) {
    const feat = setFeaturesData(features)

    return (
        <Grid container p={0} alignItems={'baseline'} justifyContent='space-between'>
            {Object.keys(feat).map((el) => (
                <Grid container xs={12} md={5.5} mb={2} p={0}>
                    <Grid item xs={12}>
                        <Typography sx={{fontSize:'25px', color: 'white', fontWeight: 600}}>{FEATURES[el]}</Typography>
                    </Grid>
                    <Grid item xs={12} className='line_features' mb={1}></Grid>
                    <Grid container justifyContent={'flex-start'} p={0} xs={12} spacing={1} alignItems={'flex-start'}>
                        {Object.keys(feat[el]).map((e) => (
                            feat[el][e] === true && <Grid item xs={4} > <Button fullWidth className='btn_feature_option'>{OPTIONS[e]}</Button> </Grid>
                        ))}
                    </Grid>
                </Grid>


            ))}
        </Grid>
    )
}

