import axios from 'axios'
import { Cookies } from 'react-cookie'



const cookie = new Cookies()

const URL = process.env.REACT_APP_API_DEV

function checkUser() {

    try {
        axios.get(`${URL}/auth/user/`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    'X-CSRFToken': cookie.get('csrftoken'),
                    'Content-Type': 'application/json',
                }
            }
        ).then((res) => {

            let data = res.data
            if (!data.is_staff && (data.company.name === '' || data.company.address === '' || data.first_name === '' ||
                data.last_name === '' || data.phone_number === '')) {
                    
                if (!data.superuser && window.location.pathname !== '/admin_panel/profile' && window.location.pathname !== '/projects') {
                    window.location.href = '/admin_panel/profile'
                }
            }

            else if (data.tariff === "none") {

                if (window.location.pathname !== '/admin_panel/pricing' && window.location.pathname !== '/projects') {
                    window.location.href = '/admin_panel/pricing'
                }
            }
        }).catch(err => {

        })
    }
    catch {

    }

}


export async function api(endpoint, method = 'GET', data, noAuth = false, { ...customConfig } = {}) {


    const csrftoken = cookie.get('csrftoken')
    const access_token = localStorage.getItem('access_token')

    const headers =

        !noAuth
            ? {
                Authorization: `Bearer ${access_token}`,
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
            }
            : {
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
            }

    const config = {
        url: `${URL}${endpoint}`,
        method: method,
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers
        },
        data: data,
    }

    if (!noAuth) {
        checkUser()
    }


    async function makeRequest(config) {
        try {
            const response = await axios(config)
            data = await response.data
            return {
                status: response.status,
                data
            }

        }
        catch (err) {
            console.log(err)
            if (err.response.status === 401) {

                try {
                    const res = await axios.post(`${URL}/auth/token/refresh/`,
                        { refresh: localStorage.getItem('refresh_token') })

                    localStorage.setItem('access_token', res.data.access)
                    localStorage.setItem('refresh_token', res.data.refresh)

                    config.headers = {
                        ...config.headers,
                        Authorization: `Bearer ${res.data.access}`,
                    }
                    return makeRequest(config)

                }
                catch (err) {
                    removeStorageAndGoToLogin()
                }
            }
            return Promise.reject(err)
        }
    }

    return makeRequest(config)
}


export const removeStorageAndGoToLogin = () => {
    localStorage.clear()

    window.location.href = '/sign_in'
}