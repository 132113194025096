import * as React from 'react'
import './burger_menu.css'
import { Grid} from '@mui/material'
import TreeMenu from './components/TreeMenu'


export default function BurgerMenu(props) {

    return (
        <Grid container item p={1} pt={2} className='bm-menu-wrap' alignItems='start'>
            <Grid container item p={0}>

                <Grid item xs={12} align="center" >
                    <TreeMenu userInfo={props.userInfo} swStatus={props.isOpen}/>
                </Grid>

            </Grid>
        </Grid>
    )
}