import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../api/api'

const initialState = {
    projects: [],
    specification: {},
    status: 'idle',
    selected_project_id: Number()
}

// Fetch actions
export const fetchProjects =
    createAsyncThunk('projects/fetchProjects', async () => {
        const response = await api(`/api/projects/`)
        return response.data
    })

export const fetchSingleProject = 
    createAsyncThunk('project/fetchSingleProject', async(project_id) => {
        const response = await api(`/api/projects/${project_id}/`)
        return response.data
    })

// Update actions

export const editProject =
    createAsyncThunk('projects/editProject', async({data, projectId}) => {
        const response = await api(`/api/projects/${projectId}/`, 'PATCH', data)
        return response.data
    })

export const updateMediaPosition =
    createAsyncThunk('projects/updateMediaPosition', async({position, media_id, project_id}) => {
        await api(`/api/project_media/${media_id}/`, 'PATCH', {'position': position})
        return {'position': position, 'media_id': media_id, 'project_id': project_id}
    })

// Delete actions

export const deleteProject =
    createAsyncThunk('project/deleteProject', async(project_id) => {
        const response = await api(`/api/projects/${project_id}/`, 'DELETE')
        return response.data
    })

export const deleteMedia =
    createAsyncThunk('projects/deleteMedia', async({media_id, project_id}) => {
        await api(`/api/project_media/${media_id}/`,
            'DELETE')
        return {'project': project_id, 'pk': media_id}
    })

export const deleteAllMedia =
    createAsyncThunk('project/deleteAllMedia', async({project_id}) => {
        await api('/api/project_media/destroy_by_project/', 'DELETE', {}, false, {
            params: {
                'project_id': project_id
            }
        })

        return {'project': project_id}
    })

export const deleteDrawing =
    createAsyncThunk('projects/deleteDrawing', async({draw_id, project_id}) => {
        await api(`/api/project_drawings/${draw_id}/`,
            'DELETE')
        return {'project': project_id, 'pk': draw_id}
    })

export const deleteCertificate =
    createAsyncThunk('projects/deleteCertificate', async({cert_id, project_id}) => {
        await api(`/api/project_certificates/${cert_id}/`,
            'DELETE')
        return {'project': project_id, 'pk': cert_id}
    })

// Add-al actions

export const publishProject =
    createAsyncThunk('project/publishProject', async(projectId) => {
        const response = await api(`/api/projects/${projectId}/make_publish/`, 'PATCH')
        return response.data
    })


export const confirmProject = 
    createAsyncThunk('project/confirmProject', async(projectId) => {
        const response = await api(`/api/projects/${projectId}/make_confirm/`, 'PATCH')
        return response.data
    })

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProjectId(state, action) {
            state.selected_project_id = action.payload.project_id
        },
        updateGeneralProjectInfo(state, action) {
            let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
            for (let field in action.payload['data']){
                state.projects[pr_id][field] = action.payload['data'][field]
            }
        },
        updateDetailsProjectInfo(state, action) {
            let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
            state.projects[pr_id].details = action.payload['data']
        },
        updateLocationProjectInfo(state, action) {
            let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
            state.projects[pr_id].location = action.payload['data']
        },
        addProjectMedia(state, action) {
            let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
            let media_length = state.projects[pr_id].media.length
            Object.keys(action.payload['data']).forEach((k) => { 
                state.projects[pr_id].media[Number(media_length)+Number(k)] = action.payload['data'][k]
            })
        },
        addProjectDrawings(state, action) {
            let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
            let drawings_length = state.projects[pr_id].drawings.length
            Object.keys(action.payload['data']).forEach((k) => { 
                state.projects[pr_id].drawings[Number(drawings_length)+Number(k)] = action.payload['data'][k]
            })
        },
        addProjectCertificates(state, action) {
            let pr_id = state.projects.findIndex((project) => project.id === action.payload['id'])
            let certificates_length = state.projects[pr_id].certificates.length
            Object.keys(action.payload['data']).forEach((k) => { 
                state.projects[pr_id].certificates[Number(certificates_length)+Number(k)] = action.payload['data'][k]
            })
        },
         
    },
    extraReducers(builder) {
        builder
            // Fetch actions
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.projects = action.payload
            })
            .addCase(fetchSingleProject.fulfilled, (state, action) => {
                state.projects = state.projects.concat(action.payload)
                state.selected_project_id = action.payload.id
            })

            // Update actions
            .addCase(editProject.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['pk'])
                state.projects[pr_id] = action.payload
            })

            .addCase(updateMediaPosition.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['project_id'])
                let media_id = state.projects[pr_id].media.findIndex((item) => item.id === action.payload['media_id'])
                state.projects[pr_id].media[media_id].position = action.payload['position']
                state.projects[pr_id].media = state.projects[pr_id].media.sort((a, b) => a.position > b.position ? 1 : -1)
            })

            // Delete actions
            .addCase(deleteMedia.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['project'])
                let im_id = state.projects[pr_id].media.findIndex((image) => image.id === action.payload['pk'])
                state.projects[pr_id].media.splice(im_id, 1)
            })

            .addCase(deleteAllMedia.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['project'])
                state.projects[pr_id].media = []
            })

            .addCase(deleteDrawing.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['project'])
                let draw_id = state.projects[pr_id].drawings.findIndex((draw) => draw.id === action.payload['pk'])
                state.projects[pr_id].drawings.splice(draw_id, 1)
            })

            .addCase(deleteCertificate.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['project'])
                let cert_id = state.projects[pr_id].certificates.findIndex((cert) => cert.id === action.payload['pk'])
                state.projects[pr_id].certificates.splice(cert_id, 1)
            })

            .addCase(publishProject.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['pk'])
                state.projects[pr_id].published = action.payload['published']
            })

            .addCase(confirmProject.fulfilled, (state, action) => {
                let pr_id = state.projects.findIndex((project) => project.id === action.payload['pk'])
                state.projects[pr_id].status = 'confirmed'
            })
    }
})

export default projectsSlice.reducer

export const selectAllProjects = (state) => state.projects.projects

export const selectProjectId = (state) => state.projects.selected_project_id

export const selectProject = (state, action) => {
    if (action === 'edit' || action === 'view') {
        return state.projects.projects.find((project) => project.id === state.projects.selected_project_id)
    }
}

export const countUnconfirmedProjects = (state) => 
    state.projects.projects.filter((project) => project.status === 'created').length

export const selectStatusProjects = (state) => state.projects.status

export const {setProjectId, updateDetailsProjectInfo, updateLocationProjectInfo, updateGeneralProjectInfo,
     addProjectMedia, addProjectDrawings, addProjectCertificates} = projectsSlice.actions
