import { Box } from '@mui/material';
import ButtonAppBar from './components/ToolBar/ToolBar';
import Header from "./Header/Header";
import WeAre from "./WhoWeAre/WhoWeAre";
import FeaturedProjects from "./Projects/Projects";
import Testimony from "./Testimonials/Testimonials";
import News from "./LatestNews/Latest_news";
import Footer from "./components/Footer/Footer";
import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import "./home.css";
import "./style.css";


export default function HomePage() {

    const [url_background, setUrl] = useState('')
    const [loader, setLoader] = useState(true)


    useEffect(() => {

        let url = process.env.REACT_APP_API_DEV
        axios.get(`${url}/api/slider/`).then((res) => {
            setUrl(res.data['photo'])
        })


        const delay = 1200;
        setTimeout(() => {
            setLoader(false)
        }, delay);

    }, [])

    return (
        !loader  ?
            <Box>
                <ButtonAppBar mode="tran" page='home' />
                <Header url_background={url_background} />
                <WeAre />
                <FeaturedProjects />
                <Testimony />
                <News />
                <Footer />
            </Box>
            :
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh !important' }}>
                <CircularProgress />
            </Box>

    )
}