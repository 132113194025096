
import {
    Grid, Card, CardContent, IconButton, Typography, Table, TableBody,
    TableRow, TableCell, CardActions, Button
} from '@mui/material'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react'
import '../pricing.css'


export default function InvestorPriceList() {

    const [open, setOpen] = useState(false)

    return (
        <Grid container p={0}>
            <Grid container item  xs={12} sm={6} lg={4} p={2}>
                <Card sx={{ width: '-webkit-fill-available' }}>
                    <CardContent>
                        <Grid container p={0} justifyContent='center'>

                            <Grid item xs={12} align='center' pt={4}>
                                <IconButton aria-label="delete" size="large" className='price_icon'>
                                    <StarBorderIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='h6' className='tariff_name_h6'>
                                    Single for <span className='span_h6'>3 Month</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='caption' className='tariff_description'>
                                    Create one end product for a client, transfer that end product to your client,
                                    charge them for your services. The license is then transferred to the client.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='h4'>
                                    <sup>$</sup>200<span className='tariff_price'>/Lifetime</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>

                                <Table size='small'>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon color='success' /></TableCell>
                                            <TableCell> Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon color='success' /></TableCell>
                                            <TableCell>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                            </Grid>


                        </Grid>
                    </CardContent>
                    <CardActions align='center' >
                        <Grid container p={0} justifyContent='center' mb={2}>
                            <Grid item xs={12}>
                                <Button variant='outlined' className='btn_order_now'
                                    onClick={() => setOpen(true)}
                                >Order Now</Button>

                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>

            <Grid container item  xs={12} sm={6} lg={4} p={2}>
                <Card sx={{ width: '-webkit-fill-available' }}>
                    <CardContent>
                        <Grid container p={0} justifyContent='center'>

                            <Grid item xs={12} align='center' pt={4}>
                                <IconButton aria-label="delete" size="large" className='price_icon'>
                                    <WorkspacePremiumIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='h6' className='tariff_name_h6'>
                                    Single for <span className='span_h6'>6 Month</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='caption' className='tariff_description'>
                                    Create one end product for a client, transfer that end product to your client,
                                    charge them for your services. The license is then transferred to the client.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='h4'>
                                    <sup>$</sup>300<span className='tariff_price'>/Lifetime</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>

                                <Table size='small'>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon color='success' /></TableCell>
                                            <TableCell> Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon color='success' /></TableCell>
                                            <TableCell>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                            </Grid>


                        </Grid>
                    </CardContent>
                    <CardActions align='center' >
                        <Grid container p={0} justifyContent='center' mb={2}>
                            <Grid item xs={12}>
                                <Button variant='outlined' className='btn_order_now'
                                    onClick={() => setOpen(true)}
                                >Order Now</Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>



            <Grid container item  xs={12} sm={6} lg={4} p={2}>
                <Card sx={{ width: '-webkit-fill-available' }}>
                    <CardContent>
                        <Grid container p={0} justifyContent='center'>

                            <Grid item xs={12} align='center' pt={4}>
                                <IconButton aria-label="delete" size="large" className='price_icon'>
                                    <EmojiEventsOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='h6' className='tariff_name_h6'>
                                    Single for <span className='span_h6'>1 Year</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='caption' className='tariff_description'>
                                    Create one end product for a client, transfer that end product to your client,
                                    charge them for your services. The license is then transferred to the client.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>
                                <Typography variant='h4'>
                                    <sup>$</sup>500<span className='tariff_price'>/Lifetime</span>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4} align='center'>

                                <Table size='small'>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon color='success' /></TableCell>
                                            <TableCell> Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon color='success' /></TableCell>
                                            <TableCell>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell sx={{ width: 0 }}><CheckIcon sx={{ opacity: .2 }} color='success' /></TableCell>
                                            <TableCell className='not_active_field'>Some text</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                            </Grid>


                        </Grid>
                    </CardContent>
                    <CardActions align='center' >
                        <Grid container p={0} justifyContent='center' mb={2}>
                            <Grid item xs={12}>
                                <Button variant='outlined' className='btn_order_now'
                                    onClick={() => setOpen(true)}
                                >Order Now</Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>

            {open &&
                <Grid container justifyContent='center'>
                    <Grid item xs={12} sm={8}>
                        <Card>
                            <CardContent>
                                <Grid container p={1} alignItems='center'>
                                    <Grid container item xs={2} p={0} justifyContent='center'>
                                        <Grid item >
                                            <ReportProblemRoundedIcon className='attention_icon'/>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={10} p={0}>
                                        <Grid item xs={12}>
                                            <Typography variant='h5' sx={{fontWeight: 600}}>
                                                We can't process your transaction right now.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='body1' sx={{fontWeight: 300}}>
                                                Our payment system for premium packages is currently under
                                                construction and being updated. 
                                                <span style={{fontWeight: 400}}>Please try again later and thank you for understanding.</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            }

        </Grid>

    )
}